export const variants = {
  confirmarRefinanciamento: {
    title: 'Confirmar Refinanciamento',
    text: 'Confirme o refinanciamento para autenticação com o i-Safe.',
    button: 'Confirmar',
  },
  autenticacaoRealizada: {
    title: 'Autenticação realizada',
    text: 'Confirmamos a autenticação do seu refinanciamento. Para contratar, selecione o botão continuar.',
    button: 'Continuar',
  },
  erroAutenticacao: {
    title: 'Erro na autenticação',
    text: 'Tivemos um problema ao autenticar. Tente novamente mais tarde.',
    button: 'Entendi',
  },
}
