import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes, TipoEmprestimo } from '@utils/enums'
import { HttpStatus } from '@services/base'
import postSimulacaoRefinanciamento, {
  SimulacaoRequest,
} from '@services/postSimulacaoRefinanciamento'
import { getParcelaAtualSimulacoes } from '@utils/functions'

import {
  selectSimulacao,
  setParcelaSelecionada,
  setSaldoDevedor,
  setTotalParcelas,
  simulacaoError,
  simulacaoRequest,
  simulacaoSuccess,
} from '../actions'
import { SimulacaoRequestPayload } from '../types'

interface ErrorType {
  response: { status: HttpStatus; data: { mensagem: string } }
  status: HttpStatus
}

const bridgeResponseHandler = (error: {
  response: { status: HttpStatus; data: { mensagem: string } }
  status: HttpStatus
}) =>
  error?.response?.status === HttpStatus.UNPROCESSABLE_ENTITY ||
  error?.status === HttpStatus.UNPROCESSABLE_ENTITY ||
  error?.response?.data?.mensagem === 'Não foi possível realizar a refinanciamento.' ||
  JSON.stringify(error).includes('Não foi possível realizar a refinanciamento')

export default function* postSimulacao(action: AnyAction) {
  const payload = action.payload as SimulacaoRequestPayload
  try {
    const contratosSelecionados = yield* select(
      (state: ApplicationState) => state.refinanciamento.contratos.contratosSelecionados || [],
    )
    const { dadosPessoais } = yield* select((state: ApplicationState) => state.refinanciamento)
    const sessionState = yield* select((state: ApplicationState) => state.session)
    const consultaMargemInss = yield* select(
      (state: ApplicationState) => state.refinanciamento.consultaMargemInss,
    )
    let taxa = 0
    let valorQuitacao = 0
    let valorProximaParcela = 0

    contratosSelecionados.map((contrato) => {
      taxa += Math.max(contrato.taxa)
      valorQuitacao += contrato.valorQuitacao
      valorProximaParcela += contrato.valorProximaParcela

      return true
    })

    const [primeiroContrato] = contratosSelecionados

    const simulacao: SimulacaoRequest = {
      cpf: dadosPessoais.cpf || sessionState.cpf || process.env.REACT_APP_MOCK_CPF || '',
      numeroPrimeiraProposta: Number(primeiroContrato.numeroProposta).toString() || '',
      taxaJuros: taxa,
      saldoDevedor: valorQuitacao,
      valorParcela: consultaMargemInss.consultaMargemRealizada
        ? consultaMargemInss.valorTotalParcela
        : valorProximaParcela,
    }
    yield put(setSaldoDevedor(valorQuitacao))
    const response = yield* call(postSimulacaoRefinanciamento, simulacao)
    yield put(simulacaoSuccess(response.data))

    // Se tiver simulações mas não tiver array de simulações com seguro / Se tiver somente o de seguro e sem o array de simulações
    if (response.data.simulacoes.length > 0 && response.data.simulacoesComSeguro.length === 0) {
      const getParcelaAtual = getParcelaAtualSimulacoes(response.data.simulacoes)
      yield put(setParcelaSelecionada(getParcelaAtual))
      yield put(setTotalParcelas(getParcelaAtual.qtdeParcelas))
      yield put(selectSimulacao(TipoEmprestimo.SEM_CREDITO_PROTEGIDO))
    } else if (
      response.data.simulacoes.length === 0 &&
      response.data.simulacoesComSeguro.length > 0
    ) {
      const getParcelaAtual = getParcelaAtualSimulacoes(response.data.simulacoesComSeguro)
      yield put(setParcelaSelecionada(getParcelaAtual))
      yield put(setTotalParcelas(getParcelaAtual.qtdeParcelas))
      yield put(selectSimulacao(TipoEmprestimo.CREDITO_PROTEGIDO))
    }
    // Caso tenha as duas opções de simulacoes deve setar as parcelas selecionadas e o total de parcelas após escolha do usuario

    yield payload.callback({
      simulacoes: response.data.simulacoes,
      simulacoesComSeguro: response.data.simulacoesComSeguro,
    })
  } catch (error) {
    // Logica para considerar diferenças dos retornos da bridge no app
    if (bridgeResponseHandler(error as ErrorType)) {
      yield put(simulacaoError())
      yield payload.callback({ simulacoes: [], simulacoesComSeguro: [] })
    } else {
      yield put(simulacaoError())
      yield put(
        showError({
          title: 'Erro na simulação',
          message: 'Ocorreu um erro ao simular seu refinanciamento',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_002_SIMULACAO,
          actionCallback: () => simulacaoRequest(payload),
        }),
      )
    }
  }
}
