import React from 'react'

import { NaoLiberado } from '@organisms'
import { StepRefin } from '@utils/enums'
import { useTrackingRefin } from '@hooks'

const RefinanciamentoNaoLiberado = () => {
  useTrackingRefin(StepRefin.NAO_LIBERADO)
  return <NaoLiberado />
}

export default RefinanciamentoNaoLiberado
