import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { hideLoading } from '@store/refinanciamento/documentos/actions'

import { dadosPropostaError, dadosPropostaRequest, dadosPropostaSucess } from '../actions'
import { DadosPropostaPayload } from '../types'

interface Props {
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
  }
  type: string
  action?: AnyAction
}

export default function* postDadosPropostaSaga({
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => dadosPropostaRequest(),
    actionTitle: 'Tentar Novamente',
  },
  action,
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  const payload = action?.payload as DadosPropostaPayload
  const propostaAntiga = appState.refinanciamento.contratos.contratosSelecionados.map(
    (item) => item.numeroProposta,
  )[0]
  try {
    const { data, status } = yield services.postDadosProposta({
      numeroProposta: String(appState.refinanciamento.proposta.numeroProposta) || propostaAntiga,
      cpf: appState.refinanciamento.dadosPessoais.cpf,
      email: appState.refinanciamento.contato.email,
      telefone: appState.refinanciamento.contato.telefone,
    })

    yield put(dadosPropostaSucess(data))
    yield put(hideLoading())
    if (payload && status === 200 && data) payload.callback()
    if (data && status === 200) return true
    yield put(showError(actionCallbackError))
    return false
  } catch (error) {
    yield put(showError(actionCallbackError))
    yield put(hideLoading())
    yield put(dadosPropostaError())
    return false
  }
}
