import { Reducer } from 'redux'

import { PropostaTypes, PropostaState } from './types'

const INITIAL_STATE: PropostaState = {
  loadingProposta: false,
  dadosProposta: {
    numeroParcelas: 0,
    valorParcela: '',
    taxa: '',
    cet: '',
    percentualIOF: '',
    valorIOF: '',
    valorLiberado: '',
    total: '',
    primeiroDesconto: '',
    ultimoDesconto: '',
    taxaNominalContrato: '',
    taxaNominalAnual: '',
    jurosContratadosPercentual: '',
    jurosContratados: '',
    valorTotalDestinadoPagamentoSeguro: '',
    percentualPropostaSeguroPrestamista: '',
    tarifaCadastro: '',
    taxaTarifaCadastro: '',
    valorSolicitado: '',
    valorSolicitadoPercentual: '',
    valorOperacao: '',
    valorDoMutuo: '',
    atributosCetMensal: '',
    atributosCetAnual: '',
    dataNascimento: '',
    nomeMae: '',
    valorTotalASerPago: '',
    quantidadeDeParcelas: 0,
    matricula: '',
    tipoLiberacao: '',
    jurosCapitalizados: true,
    estipulante: '',
    possuiSeguro: false,
    dataEmissao: '',
    enderecoResidencial: '',
    cidadeResidencial: '',
    estadoResidencial: '',
    bairroResidencial: '',
    numeroResidencial: '',
    nomeCliente: '',
    cpf: '',
    nomeConjuge: '',
    banco: '',
    agencia: '',
    conta: '',
    nomePai: '',
    cidadeNaturalidade: '',
    estadoNaturalidade: '',
    estadoCivil: '',
    nacionalidade: '',
    documentoIdentificacao: '',
    taxaEfetivaMensal: '',
    taxaEfetivaAnual: '',
    orgao: '',
    complementoResidencial: '',
    cepResidencial: '',
    cepResidencialComMascara: '',
    dddCelular: '',
    telefoneCelular: '',
    codigoContratoOriginalPortabilidade: '',
    bancoPortadoDescricao: '',
    digitoContaCorrente: '',
    profissao: '',
    escolaridade: '',
    contratosRefin: [],
    saldoDevedorTotalRefin: '',
  },
  dadosPropostaAntiga: {
    numeroParcelas: 0,
    valorParcela: '',
    taxa: '',
    cet: '',
    percentualIOF: '',
    valorIOF: '',
    valorLiberado: '',
    total: '',
    primeiroDesconto: '',
    ultimoDesconto: '',
    taxaNominalContrato: '',
    taxaNominalAnual: '',
    jurosContratadosPercentual: '',
    jurosContratados: '',
    valorTotalDestinadoPagamentoSeguro: '',
    percentualPropostaSeguroPrestamista: '',
    tarifaCadastro: '',
    taxaTarifaCadastro: '',
    valorSolicitado: '',
    valorSolicitadoPercentual: '',
    valorOperacao: '',
    valorDoMutuo: '',
    atributosCetMensal: '',
    atributosCetAnual: '',
    dataNascimento: '',
    nomeMae: '',
    valorTotalASerPago: '',
    quantidadeDeParcelas: 0,
    matricula: '',
    tipoLiberacao: '',
    jurosCapitalizados: true,
    estipulante: '',
    possuiSeguro: false,
    dataEmissao: '',
    enderecoResidencial: '',
    cidadeResidencial: '',
    estadoResidencial: '',
    bairroResidencial: '',
    numeroResidencial: '',
    nomeCliente: '',
    cpf: '',
    nomeConjuge: '',
    banco: '',
    agencia: '',
    conta: '',
    nomePai: '',
    cidadeNaturalidade: '',
    estadoNaturalidade: '',
    estadoCivil: '',
    nacionalidade: '',
    documentoIdentificacao: '',
    taxaEfetivaMensal: '',
    taxaEfetivaAnual: '',
    orgao: '',
    complementoResidencial: '',
    cepResidencial: '',
    cepResidencialComMascara: '',
    dddCelular: '',
    telefoneCelular: '',
    codigoContratoOriginalPortabilidade: '',
    bancoPortadoDescricao: '',
    digitoContaCorrente: '',
    profissao: '',
    escolaridade: '',
    contratosRefin: [],
    saldoDevedorTotalRefin: '',
  },
  dadosPropostaError: false,

  numeroProposta: undefined,
  loadingCriarProposta: false,
  errorCriarProposta: false,
  tipoDocumento: undefined,
  idIteracao: '',
  documentoId: '',

  loadingSalvarProposta: false,
  propostaEnviada: false,
  erroSalvarProposta: false,

  contato: false,
  validaLoading: false,
}

const reducer: Reducer<PropostaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PropostaTypes.DADOS_PROPOSTA_REQUEST:
      return { ...state, loadingProposta: true }
    case PropostaTypes.DADOS_PROPOSTA_SUCCESS:
      return {
        ...state,
        dadosProposta: action.payload,
        dadosPropostaError: false,
        loadingProposta: false,
      }
    case PropostaTypes.DADOS_PROPOSTA_ERROR:
      return { ...state, dadosPropostaError: true, loadingProposta: false }
    case PropostaTypes.SET_NUMERO_PROPOSTA:
      return { ...state, numeroProposta: action.payload }
    case PropostaTypes.CRIAR_PROPOSTA:
      return { ...state, loadingCriarProposta: true }
    case PropostaTypes.CRIAR_PROPOSTA_SUCCESS:
      return {
        ...state,
        loadingCriarProposta: false,
        errorCriarProposta: false,
        numeroProposta: action.payload,
      }
    case PropostaTypes.CRIAR_PROPOSTA_ERROR:
      return { ...state, loadingCriarProposta: false, errorCriarProposta: true }
    case PropostaTypes.SALVAR_PROPOSTA_REQUEST:
      return { ...state, loadingSalvarProposta: true, error: false, propostaEnviada: false }
    case PropostaTypes.SALVAR_PROPOSTA_SUCCESS:
      return { ...state, loadingSalvarProposta: false, error: false, propostaEnviada: true }
    case PropostaTypes.SALVAR_PROPOSTA_ERROR:
      return { ...state, loadingSalvarProposta: false, error: true }
    case PropostaTypes.SET_DOCUMENTO_ID:
      return { ...state, documentoId: action.payload }
    case PropostaTypes.SET_VALIDA_LOADING:
      return { ...state, validaLoading: action.payload }
    case PropostaTypes.RESET:
      return { ...INITIAL_STATE }

    case PropostaTypes.DADOS_PROPOSTA_ANTIGA_REQUEST:
      return { ...state, loadingProposta: true }
    case PropostaTypes.DADOS_PROPOSTA_ANTIGA_SUCCESS:
      return {
        ...state,
        dadosPropostaAntiga: action.payload,
        dadosPropostaError: false,
        loadingProposta: false,
      }
    case PropostaTypes.DADOS_PROPOSTA_ANTIGA_ERROR:
      return { ...state, dadosPropostaError: true, loadingProposta: false }

    default:
      return state
  }
}

export default reducer
