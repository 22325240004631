import styled from 'styled-components'

interface PropsFooter {
  marginTop?: string
}

export const Container = styled.div`
  margin: 1.5rem auto;
  max-width: 600px;
  display: grid;
  @media (max-width: 720px) {
    margin: 1.5rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 35vw;
  min-height: 90vh;
  @media (max-height: 1200px) {
    min-height: 88vh;
  }
  @media (max-height: 1050px) {
    min-height: 86vh;
  }
  @media (max-height: 890px) {
    min-height: 85vh;
  }
  @media (max-height: 750px) {
    min-height: 82vh;
  }
`

export const ContainerFooter = styled.div<PropsFooter>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop || '3vh'};
  row-gap: 2vh;
`
