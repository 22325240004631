import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import {
  contratosSuccess,
  contratosError,
  contratosResquest,
  contratosNaoElegiveis,
} from '@store/refinanciamento/contratos/actions'
import getCPF from '@store/session/sagas/getCPF'
import { setNome, setDataNascimento, setCpf } from '@store/refinanciamento/dadosPessoais/actions'
import { removeMask } from '@utils/masks'
import { ApplicationState } from '@store/types'

import { ContratosRequestPayload } from '../types'

export default function* getContratos(action: AnyAction) {
  const payload = action.payload as ContratosRequestPayload
  try {
    const cpfSession = yield* select((state: ApplicationState) => state.session.cpf)
    const cpfDados = yield* select(
      (state: ApplicationState) => state.refinanciamento.dadosPessoais.cpf,
    )
    const cpf =
      payload.cpf || cpfDados || (yield* getCPF()) || cpfSession || process.env.REACT_APP_MOCK_CPF
    if (cpf) {
      const { data } = yield* call(services.getContratos, removeMask(cpf))

      yield put(contratosNaoElegiveis(data?.naoElegivel))
      yield put(contratosSuccess(data?.contratos))

      if (data?.naoElegivel || !data?.contratos || data?.contratos.length === 0) {
        payload.semContratosCallback()
      } else {
        payload.successCallback()
      }
      yield put(setNome(data.nome))
      yield put(setDataNascimento(data.dataNascimento))
      yield put(setCpf(cpf))
    } else {
      throw new Error('Erro ao recuperar CPF')
    }
  } catch (error) {
    yield put(contratosError())
    yield put(
      showError({
        title: 'Erro ao buscar os contratos',
        message: 'Ocorreu um erro ao buscar a listagem de contratos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.RE_001_CONTRATOS,
        actionCallback: () => contratosResquest(payload),
      }),
    )
  }
}
