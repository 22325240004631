import { Reducer } from 'redux'

import { NavigationState, NavigationTypes } from './types'

const INITIAL_STATE: NavigationState = {
  isApp: true,
  origem: 'app',
  title: 'Refinanciamento',
  hideHeader: false,
  hideFooter: false,
  origemGoBack: undefined,
  isContratacaoCompleta: true,
}

const reducer: Reducer<NavigationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavigationTypes.SET_IS_APP:
      return { ...state, isApp: action.payload }
    case NavigationTypes.SET_ORIGEM:
      return { ...state, origem: action.payload }
    case NavigationTypes.SET_TITLE:
      return { ...state, title: action.payload }
    case NavigationTypes.SET_HIDE_HEADER:
      return { ...state, hideHeader: action.payload }
    case NavigationTypes.SET_HIDE_FOOTER:
      return { ...state, hideFooter: action.payload }
    case NavigationTypes.SET_ORIGEM_GO_BACK:
      return { ...state, origemGoBack: action.payload }
    case NavigationTypes.SET_IS_CONTRATACAO_COMPLETA:
      return { ...state, isContratacaoCompleta: action.payload }
    default:
      return state
  }
}

export default reducer
