import React from 'react'

import { B } from './styles'

export const TextCCBExercito = (
  <>
    O(A) <B>EMITENTE</B> declara que, previamente à emissão desta Cédula de Crédito Bancário (“
    <B>CCB</B>”), tomou ciência e anuiu quanto à Proposta de Crédito, que contém taxa de juros,
    valor solicitado, IOF, tarifa de cadastro e seguro prestamista (se contratado), elementos que
    compõem o custo efetivo total (CET) do financiamento/crédito. O cálculo do CET, como
    demonstrado, tomou em consideração a liberação do crédito e o fluxo dos pagamentos previstos,
    incluindo a taxa anual e efetiva de juros, os tributos, as tarifas e as despesas. <B>01.</B> O
    valor do mútuo será disponibilizado diretamente pelo <B>CREDOR</B>: a) em conta corrente
    bancária de titularidade do(a) <B>EMITENTE</B> destacada no Quadro 1; b) mediante ordem de
    pagamento colocada à disposição do(a) <B>EMITENTE</B> para retirada em determinada instituição
    financeira; ou c) de outra forma, conforme vier a ser ajustado entre <B>CREDOR</B> e{' '}
    <B>EMITENTE</B>. <B>02.</B> O mútuo concedido pelo <B>CREDOR</B>, definido no item 2.1 deverá
    ser pago pelo(a) <B>EMITENTE</B> ao <B>CREDOR</B>, na praça de pagamento acima definida e na
    forma estabelecida no Quadro 2, acrescido do valor de IOF e demais encargos previstos nesta{' '}
    <B>CCB</B>, caso estipulados, com a incidência, sobre todas as parcelas em questão, da taxa de
    juros prevista no item 2.3, nos limites estipulados por lei, calculada no período compreendido
    entre a data de emissão desta <B>CCB</B> e o seu vencimento. Caso seja convencionado o pagamento
    do mútuo e dos encargos em parcelas, os juros incidirão sobre o valor do saldo devedor, em
    aberto, desde a data da emissão desta <B>CCB</B> até a data do vencimento de cada prestação.
    Caso seja pactuada a cobrança de juros capitalizados, estes serão calculados ao fim de cada
    período estipulado para a capitalização e serão incorporados ao montante mutuado para fins de
    nova incidência de juros no período seguinte, conforme permitido por lei. <B>03.</B> O atraso no
    pagamento de qualquer das parcelas previstas nos itens 2.19 e 2.20 ou a não efetivação de
    amortização do saldo devedor importará no vencimento antecipado das obrigações desta <B>CCB</B>,
    de pleno direito, tornando-se imediatamente exigíveis todas as parcelas em aberto e os demais
    encargos ou o saldo devedor com seus acessórios, a ser apurado nos termos das cláusulas da
    presente <B>CCB</B>, tudo isso independentemente de aviso, notificação ou interpelação judicial
    ou extrajudicial. Ademais, poderá o <B>CREDOR</B> considerar antecipadamente vencida esta{' '}
    <B>CCB</B> se configurada ocorrência das hipóteses previstas no artigo 1.425 do Código Civil
    Brasileiro.{' '}
    <B>
      04. Em caso de mora relativa a quaisquer das obrigações estipuladas nesta <B>CCB</B>, bem como
      em todas as hipóteses de vencimento antecipado, incidirão sobre o saldo devedor do mútuo, da
      data da ocorrência do vencimento das obrigações até o dia do efetivo pagamento da
      integralidade do débito, juros remuneratórios nos moldes convencionados, juros de mora de 1%
      ao mês e multa de 2% sobre a dívida, em conformidade com a Resolução 4.882/120 do BACEN. 05.
    </B>{' '}
    Caso ocorra mora e/ou inadimplemento no cumprimento de qualquer das obrigações previstas nesta{' '}
    <B>CCB</B> , bem como se verifique alguma das hipóteses de vencimento antecipado, estará o{' '}
    <B>CREDOR</B>, de pleno direito, autorizado pelo(a) <B>EMITENTE</B> a registrar o nome deste(a)
    nos cadastros de proteção ao crédito e registro de inadimplência, tais como o SERASA/SPC/CDL,
    etc., ou ainda na Central de Riscos do Banco Central do Brasil. O(A) <B>EMITENTE</B> declara que
    concorda e está ciente de que o atraso ou a inadimplência das obrigações previstas nesta{' '}
    <B>CCB</B> dará ao <B>CREDOR</B>, de pleno direito, a prerrogativa de determinar o seu protesto.{' '}
    <B>06.</B> Vencida a dívida, ou na ocorrência de vencimento antecipado o(a) <B>EMITENTE</B>{' '}
    autoriza, desde já, o <B>CREDOR</B>, em caráter irrevogável e irretratável, a promover a
    compensação da dívida contraída, nos termos desta <B>CCB</B>, com eventuais créditos que tenha
    ou venha a ter, junto ao <B>CREDOR</B> ou a instituições conveniadas, inclusive créditos em
    conta corrente e aplicações financeiras. <B>07.</B> O(A) <B>EMITENTE</B> reconhece que a
    presente <B>CCB</B> representa título líquido, certo e exigível, pelo valor do saldo apurado em
    conformidade com suas cláusulas e condições, e reconhece ainda a sua força executiva, seja pelo
    seu próprio teor seja pela sua integração, obtida por meio de planilhas elaboradas pelo{' '}
    <B>CREDOR</B> e que demonstrem os lançamentos de débitos computados e a atualização do saldo
    devedor segundo os critérios convencionados. <B>08.</B> O <B>CREDOR</B> poderá endossar, ceder,
    vender, transferir ou entregar em penhor todos os direitos e garantias decorrentes da presente{' '}
    <B>CCB</B>, sub-rogando-se o(s) cessionário(s) em todos os direitos, interesses, prerrogativas e
    garantias decorrentes de endosso, cessão, venda, transferência ou penhor, sem que haja
    necessidade de autorização prévia do (a) <B>EMITENTE</B>. <B>09.</B> O(A) <B>EMITENTE</B>{' '}
    autoriza, de forma expressa e na forma da lei, o Comando do Exército ou seu Empregador, em
    caráter irrevogável e irretratável, a promover os descontos no beneficio previdenciário, na
    pensão ou na folha de pagamento de sua matrícula funcional identificada acima, das prestações
    mensais e sucessivas, em quantidade e valor indicados nos itens 2.19 e 2.20, bem como dos
    tributos e das tarifas incidentes sobre a presente operação, que se encontram incorporados às
    prestações ajustadas. Os descontos perdurarão até a integral liquidação do saldo devedor de
    responsabilidade do(a) <B>EMITENTE</B> em favor do <B>CREDOR</B>. Além disso, o (a){' '}
    <B>EMITENTE</B> se responsabiliza por cumprir, até a data estipulada para o vencimento, as
    obrigações derivadas desta <B>CCB</B> nas seguintes hipóteses: a) se ocorrer perda da margem
    consignável, por quaisquer motivos que impossibilitem o pagamento do valor total ou parcial das
    parcelas desta <B>CCB</B> por meio de consignação no benefício; b) quando, por falha operacional
    da entidade responsável ou do <B>CREDOR</B>, o benefício for creditado ao(à) <B>EMITENTE</B> sem
    que ocorra a retenção do valor referente à parcela, conforme previsto nesta <B>CCB</B>.{' '}
    <B>10.</B> Caso se verifique qualquer das situações listadas na Cláusula 09 o(a) <B>EMITENTE</B>{' '}
    autoriza o <B>CREDOR</B>, desde já, em caráter irrevogável e irretratável, a proceder o débito
    total ou parcial das parcelas devidas nas contas correntes, de sua titularidade, informadas no
    Quadro 1, item 1.13; 1.14; 1.15. No caso de impossibilidade do débito em contas correntes, a
    cobrança poderá ser feita por meio de Boleto Bancário, hipótese em que o(a) <B>EMITENTE</B>,
    agindo com base na boa-fé prevista no artigo 4º, inciso III, da Lei nº 8.078/90, se compromete a
    contatar o <B>CREDOR</B> de imediato e solicitar a emissão do boleto bancário para efetuar o
    pagamento. Sucessivamente, o <B>CREDOR</B>, a seu critério, e aqui expressamente autorizado
    pelo(a) <B>EMITENTE</B>, alongará automaticamente o prazo do empréstimo, sem alterar o valor da
    soma das parcelas e dos juros previamente acordados. <B>11.</B> Caso, por qualquer motivo,
    ocorra atraso na averbação, junto ao órgão empregador do(a) <B>EMITENTE</B>, dos descontos das
    parcelas originalmente pactuadas, fica, desde já, autorizado o <B>CREDOR</B> a promover a
    prorrogação das parcelas e a adequá-las ao novo fluxo de descontos, sem, contudo, ocorrer
    alteração em sua quantidade e seus valores, evitando assim acréscimo suplementar ao(à){' '}
    <B>EMITENTE</B> acordados. <B>12.</B> Fica explicitado que a não efetivação do débito previsto
    na Cláusula 10, ainda que haja saldo bastante, ou sua efetivação parcial, não tirará a certeza,
    a liquidez e a exigibilidade da dívida e não impedirá, por consequência, que o <B>CREDOR</B>{' '}
    promova a execução ou outra medida judicial contra o(a) <B>EMITENTE</B>, o que se fará
    independentemente de formalidade de qualquer natureza, especialmente aviso e notificação.{' '}
    <B>13.</B> O <B>CREDOR</B> informa ao(à) <B>EMITENTE</B> e ao(à) <B>AVALISTA</B> que as
    informações sobre o montante dos débitos e das responsabilidades constituídos nesta <B>CCB</B>,
    a teor do disposto na Resolução n° 4.571/2017 e Circular 3.870/2017 do BACEN, serão registradas
    no <B>Sistema de Informações de Crédito – SCR –, gerido pelo BACEN</B> , que tem por finalidade
    propiciar o intercâmbio entre as instituições obrigadas a prestar informações ao SCR das
    operações referentes a débitos e responsabilidades. O(A) <B>EMITENTE</B> e o(a) <B>AVALISTA</B>{' '}
    poderão ter acesso aos dados do SCR por meio da Central de Atendimento BACEN. As manifestações
    de discordância e os pedidos de correção, exclusão e registro de medidas judicias no SCR deverão
    ser dirigidas ao SCR por meio de requerimento escrito, acompanhado da respectiva decisão
    judicial quando for o caso. Ressalta-se que a consulta de qualquer informação constante no SCR
    dependerá de prévia autorização do(a) <B>EMITENTE. Parágrafo único –</B> Declarando-se ciente do
    comunicado feito no “caput” dessa cláusula, o (a) <B>EMITENTE</B>, neste ato, autoriza o{' '}
    <B>CREDOR</B> e os seus sucessores a consultarem e registrarem os débitos e as responsabilidades
    decorrentes da operações de crédito que constem ou venham a constar em nome do(a){' '}
    <B>EMITENTE</B> devedor no SCR ou nos sistemas que eventualmente substituam o SCR. <B>14.</B> O{' '}
    <B>CREDOR</B> tratará os dados pessoais (conforme definido na Lei 13.709/18 – Lei Geral de
    Proteção de Dados), recebidos neste ato e/ou coletados em razão desta <B>CCB</B>, somente para
    executar as obrigações contratuais aqui descritas, respeitando os limites e em razão das
    finalidades dispostas nesta <B>CCB</B>, e para atender obrigações legais e/ou regulatórias
    conforme a LGPD. O <B>CREDOR</B> declara (i) adotar medidas técnicas e administrativas aptas a
    proteger os dados pessoais de tratamentos acidentais ou ilícitos; (ii) exercer os melhores
    esforços para manter os dados pessoais atualizados; (iii) compartilhar os dados pessoais com
    terceiros apenas quando necessário à execução desta <B>CCB</B>; e (iv) garantir aos dados
    pessoais, que sejam eventualmente transferidos internacionalmente, um nível de proteção
    compatível com ao da LGPD. <B>15.</B> Os dados pessoais serão coletados, tratados e
    compartilhados pelo
    <B>CREDOR</B>, nos termos de sua Politica de Privacidade disponível para consulta a qualquer
    tempo por meio do site{' '}
    <a href="https://www.bancointer.com.br/politica-de-privacidade/">
      https://www.bancointer.com.br/politica-de-privacidade/
    </a>{' '}
    Ainda, o <B>CREDOR</B> esclarecerá dúvidas relacionadas à privacidade pelo telefone 3003-4070
    (capitais e regiões metropolitanas) e 0800 940 0007 (para demais localidades) ou pelo e-mail
    privacidade@bancointer.com.br. <B>16.</B> O <B>EMITENTE</B> reconhece que os tratamentos
    realizados no âmbito desta <B>CCB</B> restringem-se aos dados colhidos em razão deste
    instrumento e independem de tratamento realizado como decorrência de outra relação comercial,
    contratual ou pessoal existente entre o <B>CREDOR</B> e o(a) <B>EMITENTE</B> e de nenhuma forma
    limita, restringe, anula ou impede esse último tratamento. <B>17.</B> O(A) <B>EMITENTE</B>{' '}
    declara e garante que deseja receber publicidades, publicações e campanhas de marketing do{' '}
    <B>CREDOR</B>, reconhecendo que, a qualquer momento, poderá alterar suas preferências de
    marketing por meio da Central de Privacidade Inter ou pelos canais de atendimento. <B>18.</B>{' '}
    O(A) <B>EMITENTE</B> declara-se ciente de que os dados cadastrais por ele(a) fornecidos para a
    realização do empréstimo servirão de base para confecção de seu cadastro. Ademais, autoriza
    expressamente o <B>CREDOR</B> a informar os dados relativos a todas as obrigações assumidas, nos
    termos desta <B>CCB</B>, para que constem nos cadastros compartilhados pelo <B>CREDOR</B> com
    outras instituições conveniadas, administradas por SERASA, SPC, CDL ou por outras entidades de
    proteção ao crédito. <B>19.</B> Fica assegurado ao(à) <B>EMITENTE</B> o direito de liquidar
    antecipadamente o débito contraído junto ao <B>CREDOR</B>, nos termos da Lei nº 8.078/90,
    observadas as alterações dadas pela Lei nº 14.181/2021. Se a liquidação antecipada acarretar a
    ocorrência de parcelas em trânsito (parcelas descontadas pelo órgão consignante simultaneamente
    à liquidação do contrato), o seu reembolso, ao(à) <B>EMITENTE</B>, será feito via TED, na conta
    informada no Quadro 1 ou por meio de Ordem de Pagamento. As solicitações relativas às parcelas
    em trânsito poderão ser feitas por intermédio da Central de Atendimento ou pelo “Fale Conosco”,
    disponível no site do <B>CREDOR</B>, no endereço informado no rodapé da presente{' '}
    <B> CCB. 20.</B> O <B>CREDOR</B> obriga-se a disponibilizar a planilha de cálculo para a
    transferência da operação de crédito da instituição credora original para a instituição
    proponente, por solicitação do devedor. <B>21. </B>O(A) <B>EMITENTE</B>, os(as) avalistas e
    os(as) hipotecantes reconhecem a forma de contratação por meios eletrônicos, digitais e
    informáticos como válida e plenamente eficaz, constituindo-se título executivo extrajudicial,
    nos termos do artigo 28 da Lei nº 10.931/04 e para todos os fins de direito, ainda que seja
    estabelecida com assinatura eletrônica ou certificação fora dos padrões ICP-BRASIL, conforme
    disposto pelo art. 10 da Medida Provisória no 2.200/2001. <B>22.</B> A presente <B>CCB</B> é
    digital e disponibilizada em vias iguais e idênticas. <B>23.</B> O(A) <B>EMITENTE</B> declara,
    ao assinar esta <B>CCB</B>, que: a) compreendeu integralmente o significado e conteúdo da
    presente <B>CCB</B>; b) que o presente negócio se adequa plenamente às suas necessidades,
    interesses e objetivos; c) que o <B>CREDOR</B> prestou-lhes todas as informações necessárias
    para a(s) sua(s) livre escolha e tomada(s) de decisão, explicitando, inclusive, os seus
    direitos, deveres, responsabilidades, custos ou ônus, penalidades e riscos existentes na
    execução das operações; d) que as informações prestadas são verdadeiras e autênticas e que se
    compromete a, sempre que houver alterações em seus dados cadastrais, informar ao <B>CREDOR</B> a
    respeito; e) tem ciência de que é possível realizar a transferência de operação de crédito da
    instituição credora original para a instituição proponente, por solicitação do devedor; f) que
    está ciente de que, nas contratações realizadas por dispositivos móveis de comunicação (mobile),
    caixas eletrônicos (ATM), internet ou outro meio permitido por Lei (desde que fora do
    estabelecimento comercial), poderá desistir do contrato no prazo de até 07 (sete) dias, a contar
    do recebimento do crédito, devendo restituir o valor total concedido que lhe foi entregue,
    acrescido de eventuais tributos incidentes sobre a operação.
    <br />
    <br />
    As partes, estando de pleno acordo quanto às condições acima avençadas, confirmam as condições e
    obrigações previstas nesta <B>CCB</B> lançada pelo (a) <B>EMITENTE</B> na data prevista no item
    2.10 e em ( ) vias, sendo a via do <B>CREDOR</B> a única negociável, ao tempo em que ratificam a
    opção pelo foro de pagamento previsto no item 2.16 elegendo tal foro como o competente para a
    solução judicial de qualquer controvérsia decorrente desta <B>CCB</B>.
    <br />
    <br />
    <B>
      DECLARAÇÃO DE RECEBIMENTO DA CCB: O(A) EMITENTE declara que recebeu uma via original desta CCB
      nos exatos termos da Proposta de Crédito, da qual declara previa ciência e aceitação. Ademais,
      o (a) EMITENTE declara estar ciente e de acordo com as condições descritas nesta CCB, sendo
      que o CREDOR prestou todas as informações para sua livre tomada(s) de decisão. Declara ainda
      conhecer os canais de atendimento do CREDOR constantes nesta CCB, por meio dos quais poderá
      pedir cópia de documentos, saldo devedor ou outros serviços.
      <br />
      <br />
      Proposta válida por 2 (dois) dias a partir da data de envio do documento.
      <br />
      <br />
      Em caso de dúvida, acesse o chat do Inter por meio do endereço eletrônico{' '}
    </B>
    <a href="https://ajuda.bancointer.com.br/pt-BR">https://ajuda.bancointer.com.br/pt-BR</a> e
    clique na janela localizada no canto inferior direito para iniciar a conversa.
  </>
)
