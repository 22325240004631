import { BaseServices, HttpMethod } from '@services/base'
import { InfoInssType } from '@store/refinanciamento/consultaMargemInss/types'

export default (data: ConsultaMargemInssRequest) => {
  const pathname = process.env.REACT_APP_CONSULTA_MARGEM_INSS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ConsultarMargemInssResponse>({
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface ContratosRequest {
  numeroContrato: string
  valorParcela: number
}

export interface ConsultaMargemInssRequest {
  cpf: string
  beneficio: string
  contratos: ContratosRequest[]
}

export interface ConsultarMargemInssResponse {
  valorTotalParcela: number
  dadosBancarios: InfoInssType
}
