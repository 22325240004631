import { action, Action } from 'typesafe-actions'

import { SessionTypes } from './types'

export const setSessionId = (payload: string): Action =>
  action(SessionTypes.SET_SESSION_ID, payload)
export const setSessionCreateDate = (payload: string): Action =>
  action(SessionTypes.SET_SESSION_CREATE_DATE, payload)
export const setUtmSource = (payload: string): Action =>
  action(SessionTypes.SET_UTM_SOURCE, payload)
export const setUtmMedium = (payload: string): Action =>
  action(SessionTypes.SET_UTM_MEDIUM, payload)
export const setUtmContent = (payload: string): Action =>
  action(SessionTypes.SET_UTM_CONTENT, payload)
export const setUtmCampaign = (payload: string): Action =>
  action(SessionTypes.SET_UTM_CAMPAIGN, payload)
export const setFacebookId = (payload: string): Action =>
  action(SessionTypes.SET_FACEBOOK_ID, payload)
export const setGaClientId = (payload: string): Action =>
  action(SessionTypes.SET_GA_CLIENT_ID, payload)
export const setGaClid = (payload: string): Action => action(SessionTypes.SET_GA_CLID, payload)
export const setCpf = (payload: string | undefined): Action => action(SessionTypes.SET_CPF, payload)
