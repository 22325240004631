import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

import { Header, LargeButton, SpaceBetween } from './styles'

interface Props {
  headerTitle: string
  onClose: () => void
  description?: string
  children?: React.ReactNode
  buttonText: string
  buttonAction?: () => void
  secondaryButtonText?: string
  secondaryButtonAction?: () => void
  shouldDisableButton?: boolean
}
export const Dialog = ({
  headerTitle,
  description,
  onClose,
  children,
  buttonText,
  buttonAction,
  secondaryButtonText,
  secondaryButtonAction,
  shouldDisableButton,
}: Props) => (
  <BottomSheet onClose={() => onClose()}>
    <Header>
      <Paragraph
        fontFamily="sora"
        fontSize="1rem"
        lineHeight="1.25rem"
        color={Colors.GRAY500}
        fontWeight={600}
      >
        {headerTitle}
      </Paragraph>
    </Header>
    {description ? (
      <Paragraph fontSize="12px" lineHeight="15px" fontWeight={400} margin="0 0 24px 0">
        {description}
      </Paragraph>
    ) : (
      <></>
    )}
    {children}
    {buttonText ? (
      <LargeButton
        disabled={shouldDisableButton}
        onClick={() => {
          if (buttonAction) {
            buttonAction()
            onClose()
          } else {
            onClose()
          }
        }}
      >
        {buttonText}
      </LargeButton>
    ) : (
      <></>
    )}
    {secondaryButtonText && secondaryButtonAction ? (
      <>
        <SpaceBetween />
        <LargeButton variant="secondary" onClick={() => secondaryButtonAction()}>
          {secondaryButtonText}
        </LargeButton>
      </>
    ) : (
      <></>
    )}
  </BottomSheet>
)
