import React from 'react'

import { Dialog } from '@organisms'

interface Props {
  setShowBottomSheetCet: React.Dispatch<React.SetStateAction<boolean>>
}
export const BottomSheetCet = ({ setShowBottomSheetCet }: Props) => (
  <Dialog
    onClose={() => setShowBottomSheetCet(false)}
    headerTitle="Entenda o Custo Efetivo Total (CET)"
    description="O Custo Efetivo Total (CET) contempla todos os custos envolvidos no empréstimo, como taxa de
    juros, IOF e outros."
    buttonText="Entendi"
  />
)
