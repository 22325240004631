/**
 * Actions Types
 */

export enum ContatoTypes {
  SET_TELEFONE = '@contato/refinanciamento/SET_TELEFONE',
  SET_EMAIL = '@contato/refinanciamento/SET_EMAIL',
  SET_CHECK_TELEFONE = '@contato/refinanciamento/SET_CHECK_TELEFONE',
}

/**
 * State Type
 */

export interface ContatoState {
  readonly telefone: string
  readonly email: string
  readonly checkTelefone: boolean
}
