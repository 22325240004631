import { BaseServices, HttpMethod } from '@services/base'

export default (data: ValidaRequest) => {
  const pathname = process.env.REACT_APP_VERIFICACOES
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ContatoResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface ValidaRequest {
  cpf: string
  numeroPrimeiraProposta: string
  valorSolicitado: number
  dataNascimento: string
  simulacaoComSeguro: boolean
}

export interface ContatoResponse {
  contato: boolean
  riscoCpf: number
  pendencia: string
  descricaoPendencia: string
}
