import { BaseServices, HttpMethod } from '@services/base'

export default (data: InfoValidaRequest) => {
  const pathname = process.env.REACT_APP_INFO_VALIDA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const response = BaseServices.request({ pathname, method, host, data })
  return response
}

export interface InfoValidaRequest {
  documentoId: string
}
