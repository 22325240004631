import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import * as actions from '@store/refinanciamento/contratos/actions'
import * as actionsRefin from '@store/refinanciamento/actions'
import { setTelefone, setEmail } from '@store/refinanciamento/contato/actions'
import QueryMiddleware from '@middlewares/QueryMiddleware'
import { useMobileUserInfo } from '@hooks'
import { setCpf } from '@store/session/actions'
import { setConta, setDataNascimento, setNome } from '@store/refinanciamento/dadosPessoais/actions'
import routes from '@routes/routes'
import { Page } from '@templates'
import { GridStyled, Loading } from '@atoms'
import { timestampToDatePt } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'

const RootPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const userInfo = useMobileUserInfo()

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Refinanciamento Consignado', true)
    }
  }, [])

  // RESET STORE
  useEffect(() => {
    dispatch(actionsRefin.reset())
  }, [dispatch])

  // FETCH CONTRATOS
  useEffect(() => {
    const params = qs.parse(location?.search, { ignoreQueryPrefix: true })
    dispatch(
      actions.contratosResquest({
        cpf: params?.cpf?.toString(),
        semContratosCallback: () => {
          navigate(routes.SEM_EMPRESTIMO)
        },
        successCallback: () => {
          navigate(routes.CONTRATOS)
        },
      }),
    )
  }, [dispatch, navigate, location])

  // GET USER INFO
  useEffect(() => {
    dispatch(setTelefone(userInfo?.cellPhoneNumber || ''))
    dispatch(setEmail(userInfo?.email || ''))
    dispatch(setCpf(userInfo?.cpf || ''))
    dispatch(setNome(userInfo?.name || ''))
    dispatch(setConta(userInfo?.account || ''))
    dispatch(setDataNascimento(timestampToDatePt(Number(userInfo?.birthDate)) || ''))
  }, [userInfo, dispatch])

  return (
    <Page>
      <QueryMiddleware />
      <GridStyled>
        <Loading width={50} height={50} />
      </GridStyled>
    </Page>
  )
}

export default RootPage
