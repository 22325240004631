import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'

import { setCpf } from '../actions'

export default function* getCPF() {
  let cpf = yield* select((state: ApplicationState) => state.session.cpf)
  if (!cpf) {
    try {
      const response = yield* call(services.getCPF)
      cpf = response?.data?.cpf
      yield put(setCpf(cpf))
    } catch (error) {
      yield put(setCpf(''))
    }
  }

  return cpf
}
