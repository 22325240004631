import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postSalvarProposta, { SalvarPropostaRequest } from '@services/postSalvarProposta'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { buildProposta } from './models'
import {
  salvarPropostaError,
  salvarPropostaRequest,
  salvarPropostaSuccess,
  setDocumentoId,
} from '../actions'
import { CallbackSalvarProposta } from '../types'

export default function* postSalvarPropostaSaga(action: AnyAction) {
  const payload = action.payload as CallbackSalvarProposta
  try {
    const state = yield* select((s: ApplicationState) => s)
    const proposta: SalvarPropostaRequest = buildProposta(state)
    const { data } = yield* call(postSalvarProposta, proposta)
    yield put(setDocumentoId(data.documentoId))
    yield put(salvarPropostaSuccess())
    payload.callback()
  } catch (error) {
    yield put(salvarPropostaError())
    yield put(
      showError({
        title: 'Erro ao salvar',
        message: 'Ocorreu um erro ao salvar suas informações',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.RE_008_SALVAR_PROPOSTA,
        actionCallback: () => salvarPropostaRequest(payload),
      }),
    )
  }
}
