import { useEffect } from 'react'

import { DocumentProps } from '@pages/VisualizarDocumento/types'

const useSignatureScroll = (
  { pdfGridRef, setScrollDocument }: DocumentProps,
  docOnePage?: boolean,
) => {
  useEffect(() => {
    if (
      pdfGridRef.current &&
      (docOnePage
        ? pdfGridRef.current?.scrollHeight === pdfGridRef.current?.offsetHeight ||
          pdfGridRef.current?.scrollHeight <= pdfGridRef.current?.offsetHeight + 32
        : pdfGridRef.current?.scrollHeight === pdfGridRef.current?.offsetHeight)
    ) {
      setScrollDocument(true)
    } else {
      pdfGridRef.current?.addEventListener('scroll', () => {
        if (pdfGridRef.current) {
          if (
            Math.round(pdfGridRef.current?.scrollTop) >=
            pdfGridRef.current.scrollHeight - pdfGridRef.current.offsetHeight
          ) {
            setScrollDocument(true)
          }
        }
      })
    }
  }, [setScrollDocument, pdfGridRef, docOnePage])
}

export default useSignatureScroll
