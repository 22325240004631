import React from 'react'

import { formatCurrencySemCifrao } from '@utils/masks'
import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

import { SpanMenor } from './styles'

interface Props {
  qtdeParcelas: number
  valorParcela: number
}
const ParcelasInfo = ({ qtdeParcelas, valorParcela }: Props) => (
  <SpanMenor>
    <Paragraph
      variant="inter"
      fontSize="12px"
      lineHeight="15px"
      fontWeight="700"
      color={Colors.GRAY400}
    >
      Parcelas:
    </Paragraph>
    <Paragraph
      variant="inter"
      fontSize="12px"
      lineHeight="15px"
      fontWeight="700"
      color={Colors.PRIMARY500}
      style={{ paddingLeft: '5px' }}
    >
      {qtdeParcelas}x R$ {formatCurrencySemCifrao(valorParcela)}
    </Paragraph>
  </SpanMenor>
)

export default ParcelasInfo
