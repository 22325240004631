import React from 'react'

import { ListaDescricao } from '@molecules'
import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

interface Props {
  listaBeneficios: {
    icone: JSX.Element
    titulo: string
    descricao: string
  }[]
}
const ListaBeneficios = ({ listaBeneficios }: Props) => (
  <>
    {listaBeneficios.map((beneficio) => (
      <ListaDescricao
        iconLeft={beneficio.icone}
        style={{ marginBottom: '15px' }}
        key={beneficio.titulo}
      >
        <Paragraph
          fontWeight="700"
          fontSize="12px"
          lineHeight="15px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="0"
        >
          {beneficio.titulo}
        </Paragraph>
        <Paragraph
          fontWeight="400"
          fontSize="12px"
          lineHeight="15px"
          color={Colors.GRAY500}
          textAlign="justify"
        >
          {beneficio.descricao}
        </Paragraph>
      </ListaDescricao>
    ))}
  </>
)
export default ListaBeneficios
