import * as React from 'react'

// import Header from 'inter-frontend-lib-header/dist'
// import Footer from 'inter-frontend-lib-footer/lib/site'

import { Container, Content, ContainerFooter } from './styles'

interface Props {
  children: React.ReactElement | React.ReactElement[]
  footer?: React.ReactElement | React.ReactElement[]
  marginTop?: string
  // showHeader?: boolean
  // showFooter?: boolean
}
// TODO: utilizar header e footer quando entrar parte web

const Page = ({
  children,
  footer,
  marginTop,
}: // showHeader = true, showFooter = true
Props) => (
  <>
    {/* {showHeader && <Header colored hideLanguageSwitcher hideHelpMeBox />} */}
    <Container>
      <Content>{children}</Content>
      <ContainerFooter marginTop={marginTop}>{footer}</ContainerFooter>
    </Container>
    {/* {showFooter && <Footer />} */}
  </>
)

export default Page
