import React from 'react'

import { B } from './styles'

export const TextCCB = (
  <>
    O(A) <B>EMITENTE</B> declara que, previamente à emissão desta Cédula de Crédito Bancário (“
    <B>CCB</B>”), tomou ciência e anuiu quanto à Proposta de Crédito, que contém os valores que
    compõem Custo Efetivo Total (CET) do financiamento/crédito, sendo que o cálculo do CET, como
    demonstrado, tomou em consideração a liberação do crédito e o fluxo dos pagamentos previstos,
    incluindo a taxa anual e efetiva de juros, tributos, tarifas e despesas. <B>01.</B> O Valor do
    Mútuo será disponibilizado diretamente pelo <B>CREDOR</B> ou por intermédio de seu
    correspondente autorizado: a) em conta corrente bancária de titularidade do(a) <B>EMITENTE</B>{' '}
    destacada no Quadro 1; ou, b) mediante ordem de pagamento colocada à sua disposição para
    retirada em determinada instituição financeira; ou c) de outra forma, conforme vier a ser
    ajustado entre <B>CREDOR</B> e <B>EMITENTE</B>. <B>02.</B> O mútuo concedido pelo <B>CREDOR</B>,
    definido no item 2.1 deverá ser pago pelo(a) <B>EMITENTE</B> ao <B> CREDOR</B>, na praça de
    pagamento acima definida e na forma estabelecida no Quadro 2, acrescido do valor do IOF e demais
    encargos previstos nesta <B>CCB</B>, caso estipulados, com a incidência, sobre todas as parcelas
    em questão, da taxa de juros prevista no item 2.10, nos limites estipulados pela Instrução
    Normativa INSS/PRES n° 28/2008, calculada no período compreendido entre a data de emissão desta{' '}
    <B>CCB</B> até o vencimento da mesma. Caso seja convencionado o pagamento do mútuo e dos
    encargos em parcelas, os juros incidirão sobre o valor do saldo devedor, em aberto, desde a data
    da emissão desta <B>CCB</B> até a data do vencimento de cada prestação. Caso seja pactuada a
    cobrança de juros capitalizados, estes serão calculados ao fim de cada período estipulado para a
    capitalização e serão incorporados ao montante mutuado para fins de nova incidência de juros no
    período seguinte, conforme permitido por lei. <B>03.</B> O atraso no pagamento de quaisquer das
    parcelas previstas no item 2.6 ou a não efetivação de qualquer amortização do saldo devedor,
    importará no vencimento antecipado das obrigações desta <B>CCB</B>, de pleno direito,
    tornando-se imediatamente exigíveis todas as parcelas em aberto e demais encargos ou o saldo
    devedor com seus acessórios a ser apurado nos termos das cláusulas da presente <B>CCB</B>, tudo
    isso independente de qualquer aviso, notificação ou interpelação judicial ou extrajudicial.
    Ademais, poderá o <B>CREDOR</B> considerar antecipadamente vencida a presente <B>CCB</B>{' '}
    configurada ocorrência das hipóteses previstas no artigo 1.425 do Código Civil Brasileiro.
    <B>04</B>. Em caso de mora relativa a quaisquer das obrigações estipuladas nessa <B>CCB</B>,
    como em todas as hipóteses de vencimento antecipado, incidirão sobre o saldo devedor do mútuo,
    da data da ocorrência do vencimento das obrigações até o dia do efetivo pagamento da
    integralidade do débito, juros remuneratórios nos moldes convencionados, juros de mora de 1% ao
    mês e multa de 2% sobre a dívida, em conformidade com a Resolução 4.558/17 do BACEN. <B>05.</B>{' '}
    Caso ocorra mora e/ou inadimplemento no cumprimento de quaisquer das obrigações previstas nesta{' '}
    <B> CCB</B>, bem como quaisquer das hipóteses de vencimento antecipado, estará o <B>CREDOR</B>,
    de pleno direito, autorizado pelo(a) <B>EMITENTE</B> a registrar o nome deste nos cadastros de
    proteção ao crédito e registro de inadimplência, tais como o SERASA/SPC/CDL, etc., bem como na
    Central de Riscos do Banco Central do Brasil. O(A) <B> EMITENTE</B> declara que concorda e está
    ciente de que o atraso ou a inadimplência das obrigações previstas nesta <B>CCB</B> dará ao{' '}
    <B>CREDOR</B>, de pleno direito, a prerrogativa de determinar o protesto desta <B> CCB</B>.{' '}
    <B>06.</B> Vencida a dívida, ou na ocorrência de vencimento antecipado o(a) <B>EMITENTE</B>{' '}
    autoriza, desde já, o<B> CREDOR</B>, em caráter irrevogável e irretratável, a promover a
    compensação da dívida contraída, nos termos desta <B>CCB</B>, com eventuais créditos que o mesmo
    tenha ou venha a ter, junto ao <B>CREDOR</B> ou Instituições Conveniadas, inclusive créditos em
    conta corrente e aplicações financeiras. <B>07.</B> O(A) <B>EMITENTE</B> reconhece que a
    presente <B>CCB</B> representa título líquido certo e exigível pelo valor do saldo apurado em
    conformidade com suas cláusulas e condições, reconhecendo ainda a força executiva da presente{' '}
    <B>CCB</B> seja pelo seu próprio teor seja pela sua integração através de planilhas elaboradas
    pelo <B> CREDOR</B> que demonstrem os lançamentos de débitos computados e a atualização do saldo
    devedor segundo os critérios convencionados. <B>08.</B> O <B>CREDOR</B> poderá endossar, ceder,
    vender, transferir ou entregar em penhor todos os direitos e garantias decorrentes da presente{' '}
    <B>CCB</B>, sub-rogando-se o(s) cessionário(s) em todos os direitos, interesses, prerrogativas e
    garantias decorrentes do endosso, cessão, venda, transferência ou penhor, sem que haja
    necessidade de autorização prévia do (a){' '}
    <B>
      EMITENTE. 09. Em substituição ao formulário previsto como anexo da Instrução Normatitiva
      editada pelo INSS (IN nº 28), e conforme permissivo da cláusula sexta, §1° do Acordo de
      Cooperação Técnica celebrado entre o CREDOR, INSS e DATAPREV, publicado no DOU nº 66 de
      08/04/2015,
    </B>{' '}
    o(a) <B>EMITENTE</B> autoriza, na forma da lei o INSS ou seu Empregador, em caráter irrevogável
    e irretratável a promover os descontos no beneficio previdenciário, pensão ou na folha de
    pagamento de sua matrícula funcional identificada acima, das prestações mensais e sucessivas em
    quantidade e valor conforme itens 2.12 e 2.6, bem como dos tributos e tarifas incidentes sobre a
    presente operação, que se encontram incorporados às prestações ajustadas, descontos estes que
    deverão perdurar até integral liquidação do saldo devedor de reponsabilida de do <B>EMITENTE</B>{' '}
    em favor do <B> CREDOR</B>. Todavia, o (a) <B>EMITENTE</B> se responsabiliza por cumprir, até a
    data estipulada para o vencimento, as obrigações derivadas desta <B>CCB</B>, nas seguintes
    hipóteses: a) Se ocorrer perda da Margem Consignável, por quaisquer motivos, que impossibilitem
    o pagamento do valor total ou parcial das parcelas desta <B> CCB</B> por meio de consignação no
    benefício; b) Quando, por falha operacional da entidade pública responsável ou do <B>CREDOR</B>,
    o benefício for creditado ao(à) <B>EMITENTE</B> sem que ocorra a retenção do valor referente à
    parcela, conforme previsto nesta <B>CCB</B>.<B>10.</B> Caso se verifique qualquer das situações
    listadas na Cláusula 09 o(a) <B>EMITENTE</B> autoriza o<B> CREDOR</B>, desde já, em caráter
    irrevogável e irretratável, a proceder o débito total ou parcial das parcelas devidas nas contas
    correntes, de sua titularidade, informadas no Quadro 1, item 1.9; 1.10; 1.11. No caso de
    impossibilidade do débito em contas correntes, a cobrança poderá ser feita por meio de Boleto
    Bancário, hipótese em que o(a) <B>EMITENTE</B>, agindo com base na boa-fé prevista no artigo 4º,
    inciso III, da Lei 8.078/90, se compromete a, de imediato, contatar o <B>CREDOR</B> solicitando
    a emissão do Boleto Bancário para efetuar o pagamento. Sucessivamente, o <B>CREDOR</B>, a seu
    critério, e aqui expressamente autorizado pelo(a) <B>EMITENTE</B>, alongará automaticamente o
    prazo do empréstimo, sem alterar o valor da soma das parcelas e do juros, previamente acordados.{' '}
    <B>11.</B> Caso, por qualquer motivo, ocorra atraso na averbação junto ao órgão empregador do{' '}
    <B>EMITENTE</B>, dos descontos das parcelas, originalmente pactuadas, fica, desde já, autorizado
    o <B>CREDOR</B> a promover a prorrogação das parcelas, adequando-as ao novo fluxo de descontos,
    sem, contudo, ocorrer qualquer alteração em sua quantidade e valores, evitando assim, qualquer
    acréscimo suplementar ao <B>EMITENTE</B>.<B>12.</B> Fica explicitado que a não efetivação do
    débito previsto na Cláusula 10, ainda que haja saldo bastante, ou sua efetivação parcial, não
    tirará a certeza, liquidez e exigibilidade da dívida, não impedindo, por consequência, que o{' '}
    <B>CREDOR</B> promova a execução ou qualquer medida judicial contra o(a) <B>EMITENTE</B>, tudo o
    que se fará independente de formalidade de qualquer natureza, especialmente aviso e notificação.{' '}
    <B>13.</B> O <B>CREDOR</B> informa ao (à) <B>EMITENTE</B> e <B>AVALISTA</B> que as informações
    sobre o montante dos débitos e responsabilidades constituídos nesta <B>CCB</B>, a teor do
    disposto na Resolução n° 4.571/2017 e Circular 3.870/2017 do BACEN, serão registradas no{' '}
    <B> Sistema de Informações de Crédito – SCR – gerido pelo BACEN</B>, que tem por finalidade
    propiciar o intercâmbio entre as instituições obrigadas a prestar informações ao SCR das
    operações referentes a débitos e responsabilidades, o (a) <B>EMITENTE</B> e <B>AVALISTA</B>{' '}
    poderão ter acesso aos dados do SCR por meio da Central de Atendimento BACEN; as manifestações
    de discordância e os pedidos de correção, exclusão e registro de medidas judicias no SCR deverão
    ser dirigidas ao SCR por meio de requerimento escrito quando for o caso acompanhado da
    respectiva decisão judicial. Ressalta-se que a consulta de qualquer informação constante no SCR
    dependerá de prévia autorização do(a) <B>EMITENTE. Parágrafo único</B> – Declarando-se ciente do
    comunicado feito no “caput” dessa cláusula, o (a) <B>EMITENTE</B>, neste ato, autoriza o{' '}
    <B>CREDOR</B>, bem como seus sucessores, a consultar e registrar os débitos e responsabilidades
    decorrentes da operações de crédito que constem ou venham a constar em nome do(a){' '}
    <B>EMITENTE</B> devedor no SCR ou nos sistemas que venham a substituir o SCR. <B>14.</B> O{' '}
    <B>CREDOR</B> tratará os dados pessoais (conforme definido na Lei Geral de Proteção de Dados)
    recebidos neste ato e/ou coletados em razão desta <B>CCB</B> somente para executar as obrigações
    contratuais aqui descritas, respeitando os limites e em razão das finalidades dispostas nesta{' '}
    <B>CCB</B> e para atender obrigações legais e/ou regulatórias conforme a Lei Federal 13.709/18
    (Lei Geral de Proteção de Dados). O <B>CREDOR</B> declara (i) adotar medidas técnicas e
    administrativas aptas a proteger os dados pessoais de tratamentos acidentais ou ilícitos; (ii)
    exercer os melhores esforços para manter os dados pessoais atualizados; (iii) compartilhar os
    dados pessoais com terceiros apenas quando necessário à execução desta <B>CCB</B>; e (iv)
    garantir aos dados pessoais, que sejam eventualmente transferidos internacionalmente, um nível
    de proteção compatível com ao da LGPD. <B>15.</B> Os dados pessoais serão coletados, tratados e
    compartilhados pelo <B>CREDOR</B> nos termos de sua Politica de Privacidade disponível para
    consulta, a qualquer tempo, por meio do site{' '}
    <a href="https://www.bancointer.com.br/politica-de-privacidade/">
      {' '}
      https://www.bancointer.com.br/politica-de-privacidade/.
    </a>{' '}
    Ainda, o <B>CREDOR</B> esclarecerá dúvidas relacionadas à privacidade pelo telefone 3003-4070
    (capitais e regiões metropolitanas) e 0800 940 0007 (para demais localidades) ou pelo e-mail{' '}
    <a href="mailto:privacidade@bancointer.com.br"> privacidade@bancointer.com.br</a>.<B>16.</B> O{' '}
    <B>EMITENTE</B> reconhece que os tratamentos realizados no âmbito desta <B>CCB</B> restringem
    aos dados colhidos em razão deste instrumento e é independente ao tratamento realizado em razão
    de eventual outra relação comercial, contratual ou pessoal existente entre o <B>CREDOR</B> e{' '}
    <B>EMITENTE</B> e de nenhuma forma limita, restringe, anula ou impede esse último tratamento.
    <B>17.</B> O <B>EMITENTE</B> declara e garante que deseja receber publicidades, publicações e
    campanhas de marketing do <B>CREDOR</B>, reconhecendo que, a qualquer momento, poderá alterar
    suas preferências de marketing por meio da Central de Privacidade Inter ou pelos Canais de
    atendimento. <B>18.</B> O(A) <B>EMITENTE</B> declara-se ciente de que os dados cadastrais por
    ele(a) fornecidos para a realização do empréstimo servirão de base para confecção de seu
    cadastro. Ademais, autoriza expressamente o<B> CREDOR</B> a informar os dados relativos a todas
    as obrigações assumidas, nos termos desta <B>CCB</B>, para que constem nos cadastros
    compartilhados pelo <B>CREDOR</B> com outras instituições conveniadas, administradas pela
    SERASA, SPC, CDL ou por outras entidades de proteção ao crédito. <B>19.</B> Fica assegurado
    ao(à) <B>EMITENTE</B> o direito de liquidar antecipadamente o débito contraído junto ao{' '}
    <B>CREDOR</B>, nos termos da Resolução BACEN 3.516/2007, observada as alterações dadas pela
    Resolução 4.320/2014. Se a liquidação antecipada acarretar em parcelas em trânsito (parcelas
    descontadas pelo órgão consignante ao mesmo tempo em que o contrato foi liquidado), os
    reembolsos das mesmas, ao(à) <B>EMITENTE</B>, serão feitos via TED, na conta informada no Quadro
    1 ou por meio de Ordem de Pagamento. As solicitações das parcelas em trânsito poderão ser feitas
    através da Central de Atendimento ou pelo “Fale Conosco” disponível no site do <B>CREDOR</B>, no
    endereço informado no rodapé da presente <B>CCB</B>.<B>20.</B> O <B>CREDOR</B> obriga-se a
    disponibilizar a planilha de cálculo do saldo devedor e/ou o boleto para a liquidação antecipada
    do débito desta <B>CCB</B>, sempre que solicitado pelo <B>EMITENTE</B>, por escrito e
    acompanhado de documentos, através da Central de Atendimento ou pelo “Fale Conosco” disponível
    no site já informado na cláusula anterior. <B>21.</B> Eventuais tolerâncias do <B>CREDOR</B> com
    relação ao não cumprimento ou mora no cumprimento das obrigações e prazos desta <B>CCB</B>, não
    importarão em novação ou desistência, não podendo ser invocadas, sob hipótese alguma, pelo(a){' '}
    <B>EMITENTE</B>, ficando assegurado ao <B>CREDOR</B>, todos os seus direitos.
    <B>22.</B> Nos termos da Lei 13.313/2016, e dependendo das características da operação, o(a){' '}
    <B>EMITENTE</B> poderá oferecer em garantia a esta <B>CCB</B>, de forma irrevogável e
    irretratável: a) até 10% (dez por cento) do saldo de sua conta vinculada no Fundo de Garantia do
    Tempo de Serviço – FGTS; e/ou b) até 100% (cem por cento) do valor da multa paga pelo{' '}
    <B>EMPREGADOR,</B> em caso de despedida sem justa causa ou de despedida por culpa recíproca ou
    força maior. O(A) <B>EMITENTE</B> manifesta sua intenção de oferecer a garantia através do item
    2.24 da <B>CCB</B>.<B>23.</B> Caso o (a) <B>EMITENTE</B> tenha optado em oferecer o FGTS como
    garantia da presente operação, o mesmo declara que está ciente, concorda e autoriza, de forma
    irrevogável e irretratável, a utilização dos recursos provenientes do saque da sua conta
    vinculada do FGTS, incluindo a integralidade do valor da multa de sua rescisão do contrato de
    trabalho, para pagamento total ou parcial do saldo devedor, nos limites e condições
    estabelecidos na legislação e regulamentação vigentes <B>24.</B> Verificada a hipótese da
    cláusula 19 acima, o (a) <B>EMITENTE</B> outorga ao <B>CREDOR</B> poderes de representação junto
    à Caixa Econômica Federal, para praticar todos os atos necessários à utilização dos recursos
    provenientes ao saque da conta vinculada do FGTS para pagamento integral ou parcial do saldo
    devedor. <B>25.</B> Na operações com garantia do FGTS, se, por qualquer motivo, o saldo
    vinculado ao FGTS do (a) <B>EMITENTE</B> for insuficiente ou não puder ser utilizado para
    quitação e/ou amortização do saldo devedor, o (a) <B>EMITENTE</B> deverá efetuar o pagamento das
    parcelas em aberto diretamente ao <B>CREDOR</B>, por meio de débito em conta (autorizado desde
    já), ou por meio de boleto bancário, sob pena de incorrer nos encargos moratórios e demais
    sanções previstas no presente contrato. <B>26.</B> Caso o (a) <B>EMITENTE</B> opte por contratar
    o seguro, através do item 2.21, o (a) <B>EMITENTE</B> concorda que a(s) parcela(s) do mesmo
    poderá(ão) ser financiada(s) e que o pagamento do montante relativo ao prêmio respectivo servirá
    para garantir a quitação do saldo devedor parcial ou total do contrato assumido junto ao
    estipulante e beneficiário do seguro.{' '}
    <B>
      27. Decorrido o prazo de 90 (noventa) dias do vencimento sem pagamento, o seguro (quando
      contratado) ficará automaticamente e de pleno direito cancelado, independente de qualquer
      interpelação judicial ou extrajudicial, sem que caiba restituição de qualquer parcela do
      prêmio já paga. 28. No caso da ocorrência de Sinistro durante o período de 90 (noventa) dias
      de atraso no pagamento do prêmio, a Indenização, quando devida, será paga, no termos das
      condições contratuais/regulamento do seguro, desde que os prêmios em atraso sejam previamente
      pagos, acrescidos de juros legais e atualização monetária. 9.
    </B>{' '}
    O (a) <B>EMITENTE</B> declara estar ciente que, caso seja de seu interesse, poderá contratar o
    seguro com a Seguradora de sua preferência, vinculando-o à presente operação de crédito. Para
    tanto deverá se informar sobre os procedimentos e documentação necessária junto à Seguradora
    escolhida. <B>30.</B> Quando aplicável, e nas hipóteses de rescisão do contrato de trabalho, o
    (a) <B>EMITENTE</B> está ciente e concorda que haverá o desconto do saldo devedor nas verbas
    rescisórias até o limite de 30% (trinta por cento). <B>31.</B>O(A) <B>EMITENTE</B>, bem como{' '}
    <B>avalista</B>s e hipotecantes reconhecem a forma de contratação por meios eletrônicos,
    digitais e informáticos como válida e plenamente eficaz, constituindo título executivo
    extrajudicial nos termos do artigo 28 da Lei nº 10.931/04 e para todos os fins de direito, ainda
    que seja estabelecida com assinatura eletrônica ou certificação fora dos padrões ICP-BRASIL,
    conforme disposto pelo art. 10 da Medida Provisória nº 2.200/2001. <B>32.</B> A presente{' '}
    <B>CCB</B> é digital e disponibilizada em vias iguais e idênticas. <B>33.</B> O(A){' '}
    <B>EMITENTE</B> declara, ao assinar esta <B>CCB</B>, que: a) compreendeu integralmente o
    significado e conteúdo da presente <B>CCB</B>; b) que o presente negócio se adequa plenamente às
    suas necessidades, interesses e objetivos; c) que o <B>CREDOR</B> prestou-lhes todas as
    informações necessárias para a(s) sua(s) livre escolha e tomada(s) de decisões, explicitando,
    inclusive, os seus direitos, deveres, responsabilidades, custos ou ônus, penalidades e riscos
    existentes na execução das operações; d) que as informações prestadas são verdadeiras e
    autênticas e que se compromete a, sempre que houver alterações em seus dados cadastrais,
    informar ao <B>CREDOR</B>; e) tem ciência que é possível realizar a transferência de operação de
    crédito da instituição <B>credor</B>a original para a instituição proponente, por solicitação do
    devedor. As partes, estando de pleno acordo quanto às condições acima avençadas, confirmam as
    condições e obrigações previstas nesta <B>CCB</B> lançada pelo(a) <B>EMITENTE</B> na data
    prevista no item 2.3 e em ( ) vias, sendo a via do <B>CREDOR</B> a única negociável, ao tempo em
    que ratificam a opção pelo foro de pagamento previsto no item 2.16 elegendo tal foro como o
    competente para a solução judicial de qualquer controvérsia decorrente desta{' '}
    <B>CCB DECLARAÇÃO DE RECEBIMENTO DA CCB</B>: O(A) <B>EMITENTE</B> declara que recebeu uma via
    original desta <B> CCB</B> nos exatos termos da Proposta de Crédito da qual declara previa
    ciência e aceitação. Ademais, o (a) <B> EMITENTE</B> declara estar ciente e de acordo com as
    condições descritas nesta <B>CCB</B>, sendo que o<B> CREDOR</B> prestou todas as informações
    para sua livre tomada(s) de decisões. Declara que conhece os canais de atendimento do{' '}
    <B>CREDOR</B> constantes nesta <B>CCB</B>, seja para solicitação de cópia de documentos, saldo
    devedor ou outros serviços. O Banco não cobra e não autoriza que cobrem em seu nome nenhum valor
    pela intermediação e/ou liberação do empréstimo consignado o qual está pleiteando junto ao{' '}
    <B>CREDOR</B>.
  </>
)
