import { AnyAction } from 'redux'
import { select, call, put } from 'typed-redux-saga'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import postDocumentoAssincrono from '@services/postDocumentoAssincrono'

import { sendDocumentoAssincrono, setLoading } from '../actions'
import { SendDocumentoAssincronoPayload } from '../types'

export default function* postDocumentoAssincronoSaga(action: AnyAction) {
  const payload = action.payload as SendDocumentoAssincronoPayload

  try {
    yield put(setLoading(true))

    const { assinaturas } = yield* select(
      (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
    )

    yield* call(postDocumentoAssincrono, { assinaturas, anexarFB: false })
    yield payload.success()
    yield put(setLoading(false))
  } catch (error) {
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao enviar documentos',
        message: 'Ocorreu um erro ao enviar os documentos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.RE_005_ENVIAR_ASSINCRONO,
        actionCallback: () => sendDocumentoAssincrono(payload),
      }),
    )
  }
}
