import { addMonths, addDays, differenceInYears, format } from 'date-fns'

import { setTotalParcelas } from '@store/refinanciamento/simulacao/actions'
import { InfoParcelas } from '@store/refinanciamento/simulacao/types'

export const getParcelaAtualSimulacoes = (simulacoes: InfoParcelas[], qtdeParcela?: number) => {
  let parcelaReturn: InfoParcelas

  if (!qtdeParcela) {
    parcelaReturn = simulacoes?.reduce(
      (simulacaoAccumulator: InfoParcelas, simulacaoCurrent: InfoParcelas) => {
        if (simulacaoAccumulator.qtdeParcelas < simulacaoCurrent.qtdeParcelas) {
          return simulacaoCurrent
        }
        return simulacaoAccumulator
      },
    )
    setTotalParcelas(parcelaReturn.qtdeParcelas)
  } else {
    parcelaReturn = simulacoes?.find((simulacao) => simulacao.qtdeParcelas === qtdeParcela) || {
      qtdeParcelas: 0,
      valorSolicitado: 0,
      valorSolicitadoPercentual: 0,
      valorParcela: 0,
      valorLiberado: 0,
      valorIOF: 0,
      percentualIOF: 0,
      valorFinanciado: 0,
      cETAm: 0,
      cETAe: 0,
      taxaNominalAm: 0,
      taxaNominalAa: 0,
      jurosContratados: 0,
      jurosContratadosPercentual: 0,
      dataUltimoVencimento: '',
      dataPrimeiroVencimento: '',
    }
  }
  return parcelaReturn
}

export const validaEnvHomeByBridge = (origemGoBack: string, isConsignado?: boolean) => {
  if (origemGoBack === 'emprestimoinss') return `${process.env.REACT_APP_HOME_EMPRESTIMO}/inss`

  if (origemGoBack === 'emprestimosanguelaranja')
    return `${process.env.REACT_APP_SIMULADOR_CONSIGNADO}/novo?origem=app&familia=publico&origemgoback=emprestimo&sangueLaranja=true`

  return isConsignado
    ? `${process.env.REACT_APP_HOME_EMPRESTIMO}/consignado`
    : process.env.REACT_APP_HOME_EMPRESTIMO
}

export const idadePelaDataNascimento = (dataNascimento: string): number => {
  const data = new Date()
  const nascimento = new Date(dataNascimento)
  let idade = data.getFullYear() - nascimento.getFullYear()
  const mes = data.getMonth() - nascimento.getMonth()

  if (mes < 0 || (mes === 0 && data.getDate() < nascimento.getDate())) {
    idade -= 1
  }

  return idade
}

export const timestampToDatePt = (timestamp: number) => {
  if (!timestamp) return null
  const getDate =
    new Date(timestamp).getUTCDate() < 10
      ? `0${new Date(timestamp).getUTCDate()}`
      : new Date(timestamp).getUTCDate()
  const getMonth =
    new Date(timestamp).getMonth() + 1 < 10
      ? `0${new Date(timestamp).getMonth() + 1}`
      : new Date(timestamp).getMonth() + 1
  const getYear = new Date(timestamp).getFullYear()
  return `${getDate}/${getMonth}/${getYear}`
}

export const DateFormat = (date: Date, pattern = 'YYYY-MM-DDTHH:mm:ss.SSSZZ') =>
  format(date, pattern)

export const CurrentDate = () => DateFormat(new Date())

export const describeAge = (date: Date, currentDate = new Date(), hasSumDate = false) => {
  const now = currentDate
  let birthday = addMonths(date, 1)
  birthday = addDays(birthday, 1)
  let years = differenceInYears(now, birthday)

  if (
    date.getMonth() + 1 === currentDate.getMonth() + 1 &&
    date.getDate() > currentDate.getDate() &&
    hasSumDate
  ) {
    years += 1
  }
  return years
}

/**
 * Converte um valor monetário que esta em string ex: 1.250,45
 * para um valor float ex: 1250.45
 * @param valueString String
 * @returns ex: 1250.45
 */
export const convertStringValueToNumber = (valueString: string) => {
  const removedDots = valueString.replace('.', '')
  const replacedCommas = removedDots.replace(',', '.')
  return parseFloat(replacedCommas)
}
