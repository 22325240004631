import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'

import { finalizarPropostaErro, finalizarPropostaSuccess } from '../actions'

export default function* postFinalizarPropostaSaga({ payload }: AnyAction) {
  const { numeroProposta } = yield* select(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )
  try {
    yield services.postPropostaFinalizar({
      codigoProposta: Number(numeroProposta),
    })
    yield put(finalizarPropostaSuccess())
    yield payload.callback()
  } catch (error) {
    try {
      yield services.postPropostaFinalizar({
        codigoProposta: Number(numeroProposta),
      })
      yield put(finalizarPropostaSuccess())
      yield payload.callback()
    } catch (err) {
      yield put(finalizarPropostaErro())
    }
  }
}
