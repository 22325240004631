import React from 'react'

import { Dialog } from '@organisms'
import { Paragraph } from '@atoms'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
  titleVariant: string
  descriptionVariant: string
  buttonVariant: string
  callFunction: () => void
}
export const BottomSheetVariant = ({
  setShowBottomSheet,
  titleVariant,
  descriptionVariant,
  buttonVariant,
  callFunction,
}: Props) => (
  <Dialog
    onClose={() => setShowBottomSheet(false)}
    headerTitle={titleVariant}
    buttonText={buttonVariant}
    buttonAction={callFunction}
  >
    <Paragraph
      fontSize="14px"
      lineHeight="17px"
      fontWeight={400}
      margin="0 0 24px 0"
      textAlign="left"
    >
      {descriptionVariant}
    </Paragraph>
  </Dialog>
)
