import React from 'react'

import { PagePdf, TemplatesPDF } from '@interco/cp-react-ui-lib'
import { useSignatureScroll } from '@hooks'

import { DocumentProps } from '../types'

const PublicoVulneravel = ({ pdfGridRef, setScrollDocument }: DocumentProps) => {
  useSignatureScroll({ pdfGridRef, setScrollDocument })

  return (
    <PagePdf variant="web">
      <TemplatesPDF.PagePublicoVulneravel />
    </PagePdf>
  )
}

export default PublicoVulneravel
