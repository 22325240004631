import { BaseServices, HttpMethod } from '@services/base'

export default () => {
  const pathname = process.env.REACT_APP_GET_CPF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<{ cpf: string }>({
    pathname,
    method,
    host,
  })

  return response
}
