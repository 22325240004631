enum routes {
  SEM_EMPRESTIMO = '/refinanciamento/sem-emprestimo',
  CONFIRMAR_CONTATO = '/refinanciamento/confirmar-contato',
  DADOS_RECEBIDOS = '/refinanciamento/dados-recebidos',
  CONTRATOS = '/refinanciamento/contratos',
  RESULTADO = '/refinanciamento/resultado',
  PARCELAMENTO = '/refinanciamento/parcelamento',
  ROOT = '/refinanciamento',
  NAO_LIBERADO = '/refinanciamento/nao-liberado',
  AUTORIZACAO = '/refinanciamento/autorizacao',
  SIMULACAO = '/refinanciamento/simulacao',
  ONBOARDING_CONTRATO = '/refinanciamento/onboarding-contrato',
  CONFERIR_REFINANCIAMENTO = '/refinanciamento/conferir-refinanciamento',
  LISTA_DOCUMENTOS = '/refinanciamento/lista-documentos',
  VISUALIZAR_DOCUMENTO = '/refinanciamento/visualizar-documento',
}
export default routes
