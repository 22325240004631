import React from 'react'

import { Page } from '@templates'
import { GridStyled, Paragraph } from '@atoms'
import { ErrorIcon } from '@icons/Signals'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'

interface Props {
  erroTitle: string
  erroMessage: string
  onClick: () => void
  footerText: string
}
const ErrorPage = ({ erroTitle, erroMessage, onClick, footerText }: Props) => (
  <Page
    footer={
      <Button variant="primary" onClick={onClick}>
        {footerText}
      </Button>
    }
    marginTop="35vh"
  >
    <GridStyled>
      <ErrorIcon />
    </GridStyled>

    <Paragraph
      variant="sora"
      fontSize="24px"
      fontWeight="600"
      lineHeight="30px"
      color={Colors.GRAY500}
      textAlign="left"
      margin="42px 0 0 0"
    >
      {erroTitle}
    </Paragraph>
    <Paragraph
      textAlign="left"
      variant="inter"
      fontSize="14px"
      fontWeight="400"
      lineHeight="17px"
      color={Colors.GRAY400}
      margin="10px 0"
    >
      {erroMessage}
    </Paragraph>
  </Page>
)

export default ErrorPage
