import { BaseServices, HttpMethod } from '@services/base'

export default async (data: DocumentoAssinaturaRequest) => {
  const pathname = process.env.REACT_APP_POST_DOCUMENTO_ASSINCRONO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface DocumentoAssinaturaRequest {
  assinaturas: string[]
  anexarFB: boolean
}
