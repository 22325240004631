import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { TipoEmprestimo } from '@utils/enums'
import postValidaRefin, { ValidaRequest } from '@services/postValidaRefin'
import { getParcelaAtualSimulacoes } from '@utils/functions'

import { ValidaRequestPayload } from '../types'
import { setLoading } from '../actions'

export default function* postValida(action: AnyAction) {
  const payload = action.payload as ValidaRequestPayload

  try {
    yield put(setLoading(true))
    const { dadosPessoais, simulacao } = yield* select(
      (state: ApplicationState) => state.refinanciamento,
    )
    const sessionState = yield* select((state: ApplicationState) => state.session)
    const contratosSelecionados = yield* select(
      (state: ApplicationState) => state.refinanciamento.contratos.contratosSelecionados || [],
    )
    const contratos = [...contratosSelecionados]
    const [primeiroContrato] = contratos

    const tipoSimulacao = simulacao?.simulacaoSelecionada
    const simulationData = simulacao[tipoSimulacao]

    const getParcelaAtual = getParcelaAtualSimulacoes(simulationData)

    const validarCPF: ValidaRequest = {
      cpf: dadosPessoais?.cpf || sessionState.cpf || '',
      numeroPrimeiraProposta: Number(primeiroContrato.numeroProposta).toString() || '',
      valorSolicitado: getParcelaAtual.valorSolicitado,
      dataNascimento: dadosPessoais?.dataNascimento,
      simulacaoComSeguro: tipoSimulacao === TipoEmprestimo.CREDITO_PROTEGIDO,
    }

    const response = yield* call(postValidaRefin, validarCPF)
    yield put(setLoading(false))
    yield payload.callback({
      contato: response?.data?.contato,
      riscoCpf: response.data?.riscoCpf,
      pendencia: response.data?.pendencia,
      descricaoPendenciaRisco: response.data?.descricaoPendencia,
    })
  } catch (error) {
    yield put(setLoading(false))
    yield payload.callback({
      contato: false,
      riscoCpf: 0,
      pendencia: '',
      descricaoPendenciaRisco: '',
    })
  }
}
