/**
 * Data Type
 */
export interface InfoParcelas {
  qtdeParcelas: number
  valorSolicitado: number
  valorSolicitadoPercentual: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  percentualIOF: number
  valorFinanciado: number
  cETAm: number
  cETAe: number
  taxaNominalAm: number
  taxaNominalAa: number
  jurosContratados: number
  jurosContratadosPercentual: number
  dataUltimoVencimento: string
  dataPrimeiroVencimento: string
}

/**
 * Actions Types
 */

export enum SimulacaoTypes {
  SIMULACAO_REQUEST = '@refinanciamento/simulacao/SIMULACAO_REQUEST',
  SIMULACAO_SUCCESS = '@refinanciamento/simulacao/SIMULACAO_SUCCESS',
  SIMULACAO_ERROR = '@refinanciamento/simulacao/SIMULACAO_ERROR',
  SALVAR_LEAD_REQUEST = '@refinanciamento/simulacao/SALVAR_LEAD_REQUEST',
  SALVAR_LEAD_SUCCESS = '@refinanciamento/simulacao/SALVAR_LEAD_SUCCESS',
  SALVAR_LEAD_ERROR = '@refinanciamento/simulacao/SALVAR_LEAD_ERROR',
  SET_PARCELA_SELECIONADA = '@refinanciamento/simulacao/SET_PARCELA_SELECIONADA',
  SET_SALDO_DEVEDOR = '@refinanciamento/simulacao/SET_SALDO_DEVEDOR',
  SET_TOTAL_PARCELAS = '@refinanciamento/simulacao/SET_TOTAL_PARCELAS',
  TRACKING_REFIN = '@refinanciamento/simulacao/TRACKING_REFIN',
  SELECT_SIMULACAO = '@refinanciamento/simulacao/SELECT_SIMULACAO',
  SET_SIMULACAO_ID = '@refinanciamento/simulacao/SET_SIMULACAO_ID',
  GET_SIMULACAO_ID = '@refinanciamento/simulacao/GET_SIMULACAO_ID',
  LOADING_SIMULACAO_ID = '@refinanciamento/simulacao/LOADING_SIMULACAO_ID',
}
/**
 * State Type
 */

export interface SimulacaoState {
  readonly parcelaAtual: InfoParcelas
  readonly simulacoes: InfoParcelas[]
  readonly simulacoesComSeguro: InfoParcelas[]
  readonly saldoDevedor: number
  readonly totalParcelas: number
  readonly loadingSimulacao?: boolean
  readonly errorSimulacao?: boolean
  readonly loadingLead?: boolean
  readonly errorLead?: boolean
  readonly simulacaoSelecionada: 'simulacoes' | 'simulacoesComSeguro'
  readonly simulacaoId: number
  readonly loadingGetSimulacao: boolean
}
/**
 * Actions Payload Types
 */
export interface CallbackSimulacaoType {
  simulacoes: InfoParcelas[]
  simulacoesComSeguro: InfoParcelas[]
}
export type SimulacaoRequestPayload = {
  callback: (params: CallbackSimulacaoType) => void
}
export type SalvarLeadRequestPayload = {
  callback: () => void
}
export type SimulacaoIdPayload = {
  callback: () => void
}
