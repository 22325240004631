import React, { useCallback, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import InterWebviewBridge from '@interco/inter-webview-bridge'
import MobileBridge from '@interco/inter-mobile-bridge'
import routesRefinanciamento from '@routes/routes'
import { isOldBridge } from '@services/base/oldBridge'
import { validaEnvHomeByBridge } from '@utils/functions'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'

interface AppGoBackType {
  App: {
    goBack: () => void
  }
}
export type WindowMobileType = AppGoBackType & typeof window

export const GoBackConfig = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const iWb = InterWebviewBridge.getInstance()
  const Window = window as WindowMobileType
  const { isApp, origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)

  const listenerMobileBack = useCallback(() => {
    if (
      location?.pathname === routesRefinanciamento.CONTRATOS ||
      location?.pathname === routesRefinanciamento.ROOT ||
      location?.pathname === routesRefinanciamento.SEM_EMPRESTIMO
    ) {
      if (
        origemGoBack === 'emprestimo' ||
        origemGoBack === 'emprestimoinss' ||
        origemGoBack === 'emprestimosanguelaranja'
      ) {
        window.location.href = validaEnvHomeByBridge(origemGoBack) || ''
      } else {
        BridgeService.backToNative()
      }
    } else {
      navigate(-1)
    }
  }, [location?.pathname, navigate, origemGoBack])

  useEffect(() => {
    if (isOldBridge()) {
      Window.App = {
        goBack: () => {
          try {
            if (
              location?.pathname === routesRefinanciamento.CONTRATOS ||
              location?.pathname === routesRefinanciamento.ROOT ||
              location?.pathname === routesRefinanciamento.SEM_EMPRESTIMO
            ) {
              if (origemGoBack === 'emprestimo' || origemGoBack === 'emprestimoinss') {
                window.location.href = validaEnvHomeByBridge(origemGoBack) || ''
              } else {
                MobileBridge.goBack()
              }
            } else {
              navigate(-1)
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
          }
        },
      }
    } else {
      iWb.addWebviewEventListener('GO_BACK', listenerMobileBack)
      return () => {
        iWb.removeWebviewEventListener('GO_BACK', listenerMobileBack)
      }
    }
    return () => null
  }, [Window, iWb, isApp, listenerMobileBack, location?.pathname, navigate, origemGoBack])

  return <></>
}
