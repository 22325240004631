import { select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'

export default function* postEditarLeadSaga({ payload }: AnyAction) {
  const { cpf } = yield* select((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { simulacaoId } = yield* select(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  try {
    // TODO: Vai existir o editar lead? Se sim qual seria o tipo? e o produto seria refinanciamento?
    const { tipo = 'Crédito Consignado Público' } = payload
    yield services.postEditarLead({
      numeroProposta: payload?.numeroProposta || '',
      cpf,
      tipo,
      situacao: payload?.situacao || '',
      produto: 'novo',
      idSimulacao: String(simulacaoId),
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  } finally {
    if (payload.callback instanceof Function) {
      payload.callback()
    }
  }
}
