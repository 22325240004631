import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import CheckFill from '@interco/icons/orangeds/SM/check-fill'
import Clear from '@interco/icons/orangeds/SM/clear'
import ChevronRight from '@interco/icons/orangeds/SM/chevron-right'
import { ApplicationState } from '@store/types'
import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Loading, Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'
import { ListaDescricao } from '@molecules'
import { TipoDocs } from '@store/refinanciamento/listaDocumentos/types'
import {
  sendDocumentoAssincrono,
  setCheckMessageRevisao,
  setDocumentoRevisao,
  setTitleDocumentoRevisao,
} from '@store/refinanciamento/documentosAssinatura/actions'
import routes from '@routes/routes'
import { setIsContratacaoCompleta } from '@store/ui/navigation/actions'
import { buscarDocumentos } from '@store/refinanciamento/documentos/actions'

import { WrapLoadingText } from './style'

const ListaDocumentos = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loadingBuscarDocumentos = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentos.loading,
  )
  const { validaLoading } = useSelector((state: ApplicationState) => state.refinanciamento.proposta)

  useEffect(() => {
    dispatch(buscarDocumentos())
  }, [dispatch, navigate])

  const { documentos, loading } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )
  const enumDocumentos = useSelector(
    (state: ApplicationState) => state.refinanciamento.listaDocumentos,
  )

  const hasAssinaturaPendente = () =>
    documentos.some(
      (item: { idTipoDocumento: TipoDocs }) =>
        !enumDocumentos[item.idTipoDocumento as TipoDocs]?.isDone,
    )

  const iconRightRender = (isDone: boolean, isLoading: boolean, isError: boolean) => {
    if (isDone) return <CheckFill height={25} width={25} color={Colors.SUCCESS500} />
    if (isError) return <Clear height={25} width={25} color={Colors.ERROR500} />
    if (isLoading) return <Loading />
    return <ChevronRight height={25} width={25} color={Colors.PRIMARY500} />
  }

  useEffect(() => {
    dispatch(setIsContratacaoCompleta(true))
  }, [dispatch])

  const handleConfirmarClick = () => {
    dispatch(
      sendDocumentoAssincrono({
        success: () => {
          navigate(routes.DADOS_RECEBIDOS)
        },
      }),
    )
  }

  if (loadingBuscarDocumentos || validaLoading) {
    return (
      <Page>
        <WrapLoadingText>
          <Loading width={50} height={50} />
          <Paragraph margin="14px 0" fontSize="14px" fontWeight={700} color={Colors.GRAY500}>
            {loadingBuscarDocumentos
              ? 'Buscando dados da proposta...'
              : 'Validando as informações...'}
          </Paragraph>
        </WrapLoadingText>
      </Page>
    )
  }

  return (
    <Page
      footer={
        <Button
          disabled={hasAssinaturaPendente() || documentos.length === 0 || loading}
          isLoading={loading}
          variant="primary"
          onClick={() => handleConfirmarClick()}
        >
          Continuar
        </Button>
      }
    >
      {documentos.length === 0 ? (
        <Alert type="error" title="Erro">
          <Paragraph fontSize="12px" color={Colors.GRAY500} margin="0">
            Ocorreu um erro ao buscar os documentos
          </Paragraph>
        </Alert>
      ) : (
        <>
          <Paragraph margin="0 0 40px">
            Estamos na reta final! Leia e aceite os termos a seguir:
          </Paragraph>

          {documentos.map((item: { idTipoDocumento: TipoDocs | string }) => {
            const document = {
              ...enumDocumentos[item?.idTipoDocumento as TipoDocs],
              idTipoDocumento: item?.idTipoDocumento,
            }
            return (
              document && (
                <ListaDescricao
                  key={item.idTipoDocumento}
                  alignAllItems="center"
                  iconRight={iconRightRender(document.isDone, document.isLoading, document.isError)}
                  margin="24px 0"
                  borderBottom
                  onClick={() => {
                    if (!document.isDone) {
                      dispatch(setDocumentoRevisao(item.idTipoDocumento))
                      dispatch(setCheckMessageRevisao(document.checkMessage))
                      dispatch(setTitleDocumentoRevisao(document.text))
                      navigate(routes.VISUALIZAR_DOCUMENTO)
                    }
                  }}
                >
                  <Paragraph
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="700"
                    color={Colors.GRAY500}
                    textAlign="left"
                  >
                    {document.text}
                  </Paragraph>
                </ListaDescricao>
              )
            )
          })}
        </>
      )}
    </Page>
  )
}

export default ListaDocumentos
