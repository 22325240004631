import styled from 'styled-components'

import { Paragraph } from '@atoms'
import { Radio } from '@interco/inter-ui/components/Radio'

export const Grid = styled.div`
  height: calc(100vh - 240px);
  overflow: scroll;
`

export const FullParagraph = styled(Paragraph)`
  width: 100%;
`
export const RadioCustom = styled(Radio)`
  & > label {
    width: 100%;
  }
`
