import { action, Action } from 'typesafe-actions'

import { Contratos, ContratoSelecionado, ContratosRequestPayload, ContratosTypes } from './types'

export const contratosSuccess = (payload: Contratos[]): Action =>
  action(ContratosTypes.CONTRATOS_SUCCESS, payload)

export const contratosNaoElegiveis = (payload: boolean): Action =>
  action(ContratosTypes.CONTRATOS_NAO_ELEGIVEIS, payload)

export const contratosError = (): Action => action(ContratosTypes.CONTRATOS_ERROR)

export const contratosResquest = (payload: ContratosRequestPayload): Action =>
  action(ContratosTypes.CONTRATOS_REQUEST, payload)

export const setContratosSelecionados = (payload: ContratoSelecionado[]): Action =>
  action(ContratosTypes.SET_CONTRATOS_SELECIONADOS, payload)

export const setMatriculaSelecionada = (payload: string): Action =>
  action(ContratosTypes.SET_MATRICULA_SELECIONADA, payload)

export const setConvenioSelecionado = (payload: string): Action =>
  action(ContratosTypes.SET_CONVENIO_SELECIONADO, payload)

export const setOrgaoSelecionado = (payload: string): Action =>
  action(ContratosTypes.SET_ORGAO_SELECIONADO, payload)

export const setConvenioCodigoSelecionado = (payload: number): Action =>
  action(ContratosTypes.SET_CONVENIO_CODIGO_SELECIONADO, payload)

export const setOrgaoCodigoSelecionado = (payload: number): Action =>
  action(ContratosTypes.SET_ORGAO_CODIGO_SELECIONADO, payload)

export const setCodProduto = (payload: number): Action =>
  action(ContratosTypes.SET_CODIGO_PRODUTO, payload)
