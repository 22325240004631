import styled from 'styled-components'

export const PageVisualizarPdf = styled.div<{
  isApp: boolean
  hasProgressStep?: boolean
}>`
  margin: 24px;
  height: auto;
`

export const PdfGrid = styled.div`
  overflow: scroll;
  height: 100vh;
  margin: 0 -24px;
`
