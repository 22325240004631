import React from 'react'

import { useSelector } from 'react-redux'

import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'

import { PageVisualizarPdf, PdfGrid } from './styles'

const Autorizacao = () => {
  const { nome, cpf } = useSelector(
    (state: ApplicationState) => state.refinanciamento.dadosPessoais,
  )

  return (
    <PageVisualizarPdf id="autorizacao-consulta-inss" isApp hasProgressStep={false}>
      <PdfGrid>
        <TemplatesPDF.PageDeclaracaoAutorizacao
          pessoa={{ nome, cpf }}
          variant="web"
          dados={{
            identificacoes: ['CPF', 'Data de Nascimento', 'Nome Completo'],
            beneficios: [
              'Número do Benefício',
              'Situação do Benefício',
              'Espécie do Benefício',
              'Indicação de que o Benefício foi Concedido por Liminar',
              'Data de Cessação do Benefício – DCB (se houver)',
              'Possui Representante Legal',
              'Possui Procurador',
              'Possui Entidade Representação',
              'Pensão Alimentícia',
              'Bloqueado para Empréstimo',
              'Data da última Perícia Médica',
              'Data do Despacho do Benefício - DDB',
              'Elégivel Para Empréstimo',
            ],
            pagamentos: [
              'UF onde o Beneficiário recebe os proventos',
              'Tipo de Crédito (Cartão ou Conta-Corrente)',
              'Indicação da Instituição Financeira que paga o benefício',
              'Agência Pagadora',
              'Conta-Corrente onde o benefício é pago',
              'Margem Consignável Disponível',
              'Margem Consignável Disponível para Cartão',
              'Valor Limite para Cartão',
              'Quantidade de empréstimos ativos/suspensos',
              'Nome do representante legal',
              'CPF do representante legal',
              'Data fim do representante legal',
            ],
          }}
          texts={[
            {
              text: `Este termo autoriza esta Instituição Financeira a consultar as informações acima descritas durante 
            um período de 30 dias. Este pedido poderá ser efetuado pela Instituição Financeira em até 45 dias após a 
            assinatura deste instrumento.`,
            },
          ]}
          assinatura={{
            isDgAssinado: true,
            cidade: 'Belo Horizonte',
            data: new Date(),
          }}
          footer={{
            atualizado: new Date('31/07/2020'),
            edicao: '1a',
            codigo: 'CP074',
            contatos: [
              'www.bancointer.com.br - Central de Atendimento 3003-4070 (Capitais e Regiões Metropolitanas)',
              'SAC 0800 940 9999 - Ouvidoria 0800 940 7772 - Deﬁciente Fala e Audição 0800 979 7099',
            ],
          }}
        />
      </PdfGrid>
    </PageVisualizarPdf>
  )
}

export default Autorizacao
