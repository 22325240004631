/**
 * Actions Types
 */

export enum ContratosTypes {
  CONTRATOS_SUCCESS = '@contratos/CONTRATOS_SUCCESS',
  CONTRATOS_ERROR = '@contratos/CONTRATOS_ERROR',
  CONTRATOS_REQUEST = '@contratos/CONTRATOS_REQUEST',
  CONTRATOS_NAO_ELEGIVEIS = '@contratos/CONTRATOS_NAO_ELEGIVEIS',
  SET_CONTRATOS_SELECIONADOS = '@contratos/SET_CONTRATOS_SELECIONADOS',
  SET_CONVENIO_SELECIONADO = '@contratos/SET_CONVENIO_SELECIONADO',
  SET_ORGAO_SELECIONADO = '@contratos/SET_ORGAO_SELECIONADO',
  SET_MATRICULA_SELECIONADA = '@contratos/SET_MATRICULA_SELECIONADA',
  SET_CONVENIO_CODIGO_SELECIONADO = '@contratos/SET_CONVENIO_CODIGO_SELECIONADO',
  SET_ORGAO_CODIGO_SELECIONADO = '@contratos/SET_ORGAO_CODIGO_SELECIONADO',
  SET_CODIGO_PRODUTO = '@contratos/SET_CODIGO_PRODUTO',
}

/**
 * Data Type
 */

export interface Contratos {
  descricaoConvenio: string
  listMatricula: Matricula[]
}

export interface Matricula {
  numeroMatricula: string
  listContrato: Contrato[]
}

export interface Contrato {
  numeroContrato: string
  numeroProposta: string
  numParcelasPagas: number
  numParcelasTotais: number
  valorProximaParcela: number
  vencimentoProximaParcela: string
  valorQuitacao: number
  valorEmprestimo: number
  taxa: number
  orgaoDesc: string
  convenioDesc: string
  indisponivel?: boolean
  convenioId: number
  orgaoId: number
  codProduto: number
}

export interface ContratoSelecionado {
  valorQuitacao: number
  numeroContrato: string
  matricula: string
  numeroProposta: string
  valorProximaParcela: number
  taxa: number
  convenioDesc: string
  orgaoDesc: string
  convenioId: number
  orgaoId: number
  codProduto: number
}

/**
 * State Type
 */

export interface ContratosState {
  readonly listaContratos?: Contratos[]
  readonly contratosError?: boolean
  readonly contratosLoading?: boolean
  readonly convenioSelecionado: string
  readonly orgaoSelecionado: string
  readonly matriculaSelecionada: string
  readonly contratosSelecionados: ContratoSelecionado[]
  readonly naoElegivel?: boolean
  readonly convenioCodigo: number
  readonly orgaoCodigo: number
  readonly codigoProduto: number
}

export interface ContratosAPI {
  contratos: Contratos[]
  naoElegivel: boolean
  nome: string
  dataNascimento: string
}

export type ContratosRequestPayload = {
  cpf?: string
  semContratosCallback: () => void
  successCallback: () => void
}
