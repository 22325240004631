import { BaseServices, HttpMethod } from '@services/base'

export default (idClienteOrigem: string, sistemaOrigem: string) => {
  const pathname = `${process.env.REACT_APP_VALIDA_CLIENTE_EXISTE}?idClienteOrigem=${idClienteOrigem}&sistemaOrigem=${sistemaOrigem}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
  })
  return response
}
