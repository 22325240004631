import { BaseServices, HttpMethod } from '@services/base'

export interface DataCreatePropostaType {
  simulacaoId: number
  tipoSimulacao: string
}

export interface CreatePropostaRequest {
  codigo: string
}

export default async (data: DataCreatePropostaType) => {
  const pathname = process.env.REACT_APP_CRIAR_PROPOSTA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = await BaseServices.request<CreatePropostaRequest>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
