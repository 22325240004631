import { BaseServices, HttpMethod } from '@services/base'

export interface EditarLeadType {
  numeroProposta: number | string
  cpf: string | number
  tipo: string
  situacao: string
  produto: string
  idSimulacao: string
}

export default async (data: EditarLeadType) => {
  const pathname = process.env.REACT_APP_EDITAR_LEAD
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
