import styled from 'styled-components'

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
`

export const WrapNoTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2px;
`

export const WrapTitleBox = styled.div`
  display: flex;
  flex-direction: row;
`

export const SpanMenor = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
`
