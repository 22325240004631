import { BaseServices, HttpMethod } from '@services/base'

export interface FinalizarPropostaType {
  codigoProposta: number
}

export default async (data: FinalizarPropostaType) => {
  const pathname = process.env.REACT_APP_PROPOSTA_FINALIZAR
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
