import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'

import {
  dadosPropostaAntigaError,
  dadosPropostaAntigaRequest,
  dadosPropostaAntigaSucess,
} from '../actions'
import { DadosPropostaPayload } from '../types'

export default function* postDadosAntigaPropostaSaga(action: AnyAction) {
  const appState = yield* select((state: ApplicationState) => state)
  const payload = action?.payload as DadosPropostaPayload
  const propostaAntiga = appState.refinanciamento.contratos.contratosSelecionados.map(
    (item) => item.numeroProposta,
  )[0]
  try {
    const { data } = yield services.postDadosProposta({
      numeroProposta: propostaAntiga,
      cpf: appState.refinanciamento.dadosPessoais.cpf,
      email: appState.refinanciamento.contato.email,
      telefone: appState.refinanciamento.contato.telefone,
    })

    yield put(dadosPropostaAntigaSucess(data))
    if (payload) payload.callback()
  } catch (error) {
    yield put(dadosPropostaAntigaError())
    yield put(
      showError({
        title: 'Erro ao buscar dados da proposta',
        message: 'Ocorreu um erro ao buscar proposta',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => dadosPropostaAntigaRequest(payload),
      }),
    )
  }
}
