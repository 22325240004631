import styled, { css } from 'styled-components'

import { Colors } from '@utils/Colors'

import { Props } from './types'

/**
 * Div principal
 */
export const SeparatorComponent = styled.div<Props>`
  height: ${(props) => props.height || `8px`};
  ${(props) => props.margin && `margin: ${props.margin};`}
  background-color: ${(props) => (props.variant === 'small' ? Colors.WHITE : Colors.GRAY200)};

  ${(props) => props.variant === 'small' && `${BorderDashed}`}
`

/**
 * Variação do separador com borda pontilhada.
 */
const BorderDashed = css`
  height: 1px;
  background-image: linear-gradient(to right, #dedfe4 50%, rgba(245, 246, 250, 0) 0%);
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
`
