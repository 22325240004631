import { BaseServices, HttpMethod } from '@services/base'

export default (data: ValidaMatriculaRequest) => {
  const pathname = process.env.REACT_APP_VALIDA_MATRICULA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ValidaMatriculaResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface ValidaMatriculaRequest {
  convenio: number
  cpf: string
  matricula: string | number
  indicativo: number
  categoria: number
  orgao: number
}

export interface ValidaMatriculaResponse {
  matriculaValida: boolean
  valorMargem: number
  message: string
}
