import { BaseServices, HttpMethod } from '@services/base'

export default (data: CloudRemoveRequest) => {
  const pathname = process.env.REACT_APP_CLOUD_MARKET_REMOVE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface CloudRemoveRequest {
  cpf_cnpj: string
  origem: string
  modalidade_operacao: string
}
