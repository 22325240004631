import { ContratosAPI } from '@store/refinanciamento/contratos/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (payload: string) => {
  const pathname = `${process.env.REACT_APP_GET_CONTRATOS}/${payload}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ContratosAPI>({
    pathname,
    method,
    host,
  })
  return response
}
