import React from 'react'

import { useSelector } from 'react-redux'

import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { removeMask } from '@utils/masks'
import { useSignatureScroll } from '@hooks'
import { convertStringValueToNumber } from '@utils/functions'

import { DocumentProps } from '../types'

const SeguroPrestamista = ({ pdfGridRef, setScrollDocument }: DocumentProps) => {
  const { cpf, nome } = useSelector(
    (state: ApplicationState) => state.refinanciamento.dadosPessoais,
  )
  const { dadosProposta } = useSelector((state: ApplicationState) => state.refinanciamento.proposta)

  // TO DO: Descobrir de onde pode pegar o valor de isPep
  const isPep = false

  const variant = 'web'

  useSignatureScroll({ pdfGridRef, setScrollDocument })

  return (
    <TemplatesPDF.PagePrestamista
      variant={variant}
      proponente={{
        cpf: Number(removeMask(cpf)) || Number(removeMask(dadosProposta?.cpf)),
        nome: nome || dadosProposta?.nomeCliente,
        dtNascimento: new Date(dadosProposta?.dataNascimento),
      }}
      coberturaSaldo={{
        checkBoxes: [
          { checked: true, description: 'Morte', name: 'Morte' },
          {
            checked: true,
            description: 'Invalidez Permanente Total por Acidente',
            name: 'Invalidez',
          },
        ],
      }}
      seguro={{
        checkBoxes: [
          {
            checked: true,
            description: 'Financiamento',
            name: 'Financiamento',
          },
          { checked: false, description: 'Boleto', name: 'Boleto' },
        ],
        conta: Number(dadosProposta?.conta),
        numParcela: dadosProposta?.quantidadeDeParcelas,
        valorParcela: convertStringValueToNumber(dadosProposta?.valorParcela),
        premioSeguro: 'Conforme política',
      }}
      representante={{
        nmCorretor: 'Inter Digital Corretora e Consultoria de Seguros Ltda',
        filial: 'Assembléia',
      }}
      termoAutorizacao={{
        nome,
        numDoc: Number(removeMask(cpf)),
        cidade: 'Belo Horizonte',
        dataAssinatura: new Date(),
        isDigitalAssinado: true,
      }}
      termo={{
        checkBoxes: [
          { checked: isPep, description: 'Sim', name: 'Sim' },
          { checked: !isPep, description: 'Não', name: 'Nao' },
        ],
      }}
      observacao={{}}
      nota={{}}
    />
  )
}

export default SeguroPrestamista
