import { Reducer } from 'redux'

import { RefinanciamentoTypes } from '@store/refinanciamento/actions'

import { ContratosState, ContratosTypes } from './types'

const INITIAL_STATE: ContratosState = {
  listaContratos: [],
  contratosLoading: false,
  contratosError: false,
  convenioSelecionado: '',
  orgaoSelecionado: '',
  matriculaSelecionada: '',
  contratosSelecionados: [],
  naoElegivel: false,
  convenioCodigo: 0,
  orgaoCodigo: 0,
  codigoProduto: 0,
}

const reducer: Reducer<ContratosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContratosTypes.CONTRATOS_SUCCESS:
      return {
        ...state,
        contratosLoading: false,
        contratosError: false,
        listaContratos: action.payload,
      }
    case ContratosTypes.CONTRATOS_ERROR:
      return { ...state, contratosLoading: false, contratosError: true }
    case ContratosTypes.CONTRATOS_REQUEST:
      return { ...state, contratosLoading: true, contratosError: false }
    case ContratosTypes.CONTRATOS_NAO_ELEGIVEIS:
      return { ...state, naoElegivel: action.payload }
    case ContratosTypes.SET_CONVENIO_SELECIONADO:
      return { ...state, convenioSelecionado: action.payload }
    case ContratosTypes.SET_ORGAO_SELECIONADO:
      return { ...state, orgaoSelecionado: action.payload }
    case ContratosTypes.SET_MATRICULA_SELECIONADA:
      return { ...state, matriculaSelecionada: action.payload }
    case ContratosTypes.SET_CONTRATOS_SELECIONADOS:
      return { ...state, contratosSelecionados: action.payload }
    case ContratosTypes.SET_CONVENIO_CODIGO_SELECIONADO:
      return { ...state, convenioCodigo: action.payload }
    case ContratosTypes.SET_ORGAO_CODIGO_SELECIONADO:
      return { ...state, orgaoCodigo: action.payload }
    case ContratosTypes.SET_CODIGO_PRODUTO:
      return { ...state, codigoProduto: action.payload }
    case RefinanciamentoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
