import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import getCPF from '@store/session/sagas/getCPF'
import { removeMask } from '@utils/masks'
import { ApplicationState } from '@store/types'
import { AnyAction } from '@reduxjs/toolkit'

import { getSimulacaoId, setLoadingGetSimulacaoId, setSimulacaoId } from '../actions'
import { SimulacaoIdPayload } from '../types'

export default function* getSimulacaoIdSaga(action: AnyAction) {
  const payload = action.payload as SimulacaoIdPayload

  try {
    yield put(setLoadingGetSimulacaoId(true))
    const cpfSession = yield* select((state: ApplicationState) => state.session.cpf)
    const cpfDados = yield* select(
      (state: ApplicationState) => state.refinanciamento.dadosPessoais.cpf,
    )
    const cpf = cpfDados || cpfSession || (yield* getCPF()) || process.env.REACT_APP_MOCK_CPF
    if (cpf) {
      const { data } = yield* call(services.getSimulacaoId, removeMask(String(cpf)))

      yield put(setSimulacaoId(data.simulacaoId))
      yield put(setLoadingGetSimulacaoId(false))
      yield payload.callback()
    } else {
      throw new Error('Erro ao recuperar CPF')
    }
  } catch (error) {
    yield put(setLoadingGetSimulacaoId(false))
    yield put(
      showError({
        title: 'Erro ao buscar dados da simulação',
        message: 'Ocorreu um erro ao buscar os dados da simulação',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.RE_006_BUSCAR_IDSIMULACAO,
        actionCallback: () => getSimulacaoId(payload),
      }),
    )
  }
}
