import { combineReducers } from 'redux'

import { ContatoState } from './contato/types'
import contato from './contato'
import { ContratosState } from './contratos/types'
import contratos from './contratos'
import { SimulacaoState } from './simulacao/types'
import simulacao from './simulacao'
import { DadosPessoaisState } from './dadosPessoais/types'
import dadosPessoais from './dadosPessoais'
import { ConsultaMargemInssState } from './consultaMargemInss/types'
import consultaMargemInss from './consultaMargemInss'
import { ValidaState } from './valida/types'
import valida from './valida'
import { ListaDocumentosState } from './listaDocumentos/types'
import listaDocumentos from './listaDocumentos'
import { DocumentosAssinaturaState } from './documentosAssinatura/types'
import documentosAssinatura from './documentosAssinatura'
import { PropostaState } from './proposta/types'
import proposta from './proposta'
import { DocumentosState } from './documentos/types'
import documentos from './documentos'

export interface RefinanciamentoState {
  contato: ContatoState
  contratos: ContratosState
  simulacao: SimulacaoState
  dadosPessoais: DadosPessoaisState
  consultaMargemInss: ConsultaMargemInssState
  valida: ValidaState
  listaDocumentos: ListaDocumentosState
  documentosAssinatura: DocumentosAssinaturaState
  proposta: PropostaState
  documentos: DocumentosState
}

export default combineReducers({
  contato,
  contratos,
  simulacao,
  dadosPessoais,
  consultaMargemInss,
  valida,
  listaDocumentos,
  documentosAssinatura,
  proposta,
  documentos,
})
