import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import services from '@services'
import { showError } from '@store/ui/error/actions'
import { setSessionId } from '@store/session/actions'
import { setDocumentoId } from '@store/refinanciamento/proposta/actions'

import {
  carregarCaptura,
  setCet,
  setCreditoProtegido,
  setEmail,
  setNome,
  setQtdParcelas,
  setTaxa,
  setTelefone,
  setValorLiberado,
  setValorParcela,
  setValorTotal,
  hideLoading,
  showLoading,
} from '../actions'

interface Props {
  notShowLoader: boolean
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
  }
  type: string
}

export default function* carregarCapturaSaga({
  notShowLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => carregarCaptura(),
    actionTitle: 'Tentar Novamente',
  },
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    if (!notShowLoader) {
      yield put(showLoading())
    }
    const clientId = appState.session.sessionId
    const { cpf } = appState.refinanciamento.dadosPessoais

    const proposta = appState.refinanciamento.proposta.numeroProposta
    const documentoid = appState.refinanciamento.proposta.documentoId
    const { data, status } = yield services.getCaptura({
      cpf,
      proposta: String(proposta),
      clientId: String(clientId),
    })
    yield put(setNome(data.nome))
    yield put(setTelefone(data.telefone))
    yield put(setEmail(data.email))
    yield put(setValorLiberado(data.valorLiberado))
    yield put(setValorParcela(data.valorParcela))
    yield put(setQtdParcelas(data.quantidadeParcelas))
    yield put(setTaxa(data.taxa))
    yield put(setCet(data.valorCET))
    yield put(setValorTotal(data.valorTotal))
    yield put(setCreditoProtegido(data.creditoProtegido))
    if (!documentoid && data.documentosId) {
      yield put(setDocumentoId(data.documentosId))
    }
    if (data.clientId) {
      yield put(setSessionId(data.clientId))
    }
    if (!notShowLoader) {
      yield put(hideLoading())
    }
    if (data && status === 200) return true
    yield put(showError(actionCallbackError))
    return false
  } catch (error) {
    yield put(showError(actionCallbackError))
    if (!notShowLoader) {
      yield put(hideLoading())
    }
    return false
  }
}
