import { Reducer } from 'redux'

import { ListaDocumentosTypes, ListaDocumentosState } from './types'

const INITIAL_STATE: ListaDocumentosState = {
  CCB: {
    text: 'Cédula de Crédito Bancário',
    checkMessage: 'Li e concordo com as informações descritas na ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  DECLARACAO_RESIDENCIA: {
    text: 'Declaração de Residência',
    checkMessage: 'Li e concordo com as informações descritas na ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  AUTORIZACAO_CONSULTA_MARGEM: {
    text: 'Autorização de consulta de dados',
    checkMessage: 'Li e concordo com as informações descritas na ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  SEGURO_PRESTAMISTA: {
    text: 'Seguro Prestamista',
    checkMessage: 'Li e concordo com as informações descritas no termo de adesão do ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  CCB_EXERCITO: {
    text: 'Cédula de Crédito Bancário - Comando Exercito',
    checkMessage: 'Li e concordo com as informações descritas na ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  ADF: {
    text: 'Autorização de Desconto em Folha de Pagamento',
    checkMessage: 'Li e concordo com as informações descritas na ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  PUBLICO_VULNERAVEL: {
    text: 'Público Vulnerável',
    checkMessage: 'Li e concordo com as informações descritas.',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  TERMO_PORTABILIDADE: {
    text: 'Termo de Portabilidade',
    checkMessage: 'Li e concordo com as informações descritas no ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
  TERMO_REFINANCIAMENTO: {
    text: 'Termo de Refinanciamento',
    checkMessage: 'Li e concordo com as informações descritas no ',
    description: '',
    pdf: null,
    isLoading: false,
    isDone: false,
    isError: false,
  },
}

const reducer: Reducer<ListaDocumentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ListaDocumentosTypes.SET_LISTA:
      return { ...state, ...action.payload }
    case ListaDocumentosTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
