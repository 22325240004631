import { BaseServices, HttpMethod } from '@services/base'

export interface DataType {
  cpf: string
  numeroProposta: string
}

export default async (data: DataType) => {
  const pathname = process.env.REACT_APP_CAPTURE_DOCUMENTS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = await BaseServices.request<CapturaDocumentoRequest>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface CapturaDocumentoRequest {
  data: {
    idInteracao: string
    numeroProposta: string
    pendentes: []
    enviados: []
  }
}
