import { action, Action } from 'typesafe-actions'

import { ConsultaMargemInssPayload, ConsultaMargemInssTypes, InfoInssType } from './types'

export const consultaMargemInssRequest = (payload: ConsultaMargemInssPayload): Action =>
  action(ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_REQUEST, payload)

export const consultaMargemInssSuccess = (payload: number): Action =>
  action(ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_SUCCESS, payload)

export const consultaMargemInssError = (): Action =>
  action(ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_ERROR)

export const setInfoInssDadosBancarios = (payload: InfoInssType): Action =>
  action(ConsultaMargemInssTypes.SET_INFO_INSS_DADOS_BANCARIOS, payload)
