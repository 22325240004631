import React from 'react'

import { Route, BrowserRouter, Routes } from 'react-router-dom'

import {
  NaoLiberado,
  SemEmprestimo,
  DadosRecebidos,
  RootPage,
  ConfirmarContato,
  ResultadoSimulacao,
  Parcelamento,
  Contratos,
  Autorizacao,
  Simulacao,
  OnboardingContrato,
  ConferirRefinanciamento,
  ListaDocumentos,
  VisualizarDocumento,
} from '@pages'
import { GoBackConfig } from '@utils/goBackConfig'

import routes from './routes'

const RoutesRefin: React.FC = () => (
  <BrowserRouter>
    <GoBackConfig />
    <Routes>
      <Route path={routes.VISUALIZAR_DOCUMENTO} element={<VisualizarDocumento />} />
      <Route path={routes.LISTA_DOCUMENTOS} element={<ListaDocumentos />} />
      <Route path={routes.CONFERIR_REFINANCIAMENTO} element={<ConferirRefinanciamento />} />
      <Route path={routes.ONBOARDING_CONTRATO} element={<OnboardingContrato />} />
      <Route path={routes.SIMULACAO} element={<Simulacao />} />
      <Route path={routes.AUTORIZACAO} element={<Autorizacao />} />
      <Route path={routes.CONTRATOS} element={<Contratos />} />
      <Route path={routes.PARCELAMENTO} element={<Parcelamento />} />
      <Route path={routes.RESULTADO} element={<ResultadoSimulacao />} />
      <Route path={routes.CONFIRMAR_CONTATO} element={<ConfirmarContato />} />
      <Route path={routes.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
      <Route path={routes.NAO_LIBERADO} element={<NaoLiberado />} />
      <Route path={routes.ROOT} element={<RootPage />} />
      <Route path={routes.SEM_EMPRESTIMO} element={<SemEmprestimo />} />
    </Routes>
  </BrowserRouter>
)

export default RoutesRefin
