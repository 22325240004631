import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { StepRefin } from '@utils/enums'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'

const useTrackingRefin = (step: StepRefin) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(trackingRefin(step))
  }, [dispatch, step])
}

export default useTrackingRefin
