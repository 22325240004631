import styled from 'styled-components'

import { DivProps } from './types'

export const Div = styled.div<DivProps>`
  display: flex;
  justify-content: space-between;
  border-top: ${({ borderTop }) => borderTop || ''};
  border-bottom: ${({ borderBottom }) => borderBottom || ''};
  padding: 9px 0;
  align-content: center;
  align-items: center;
`
export const Span = styled.span<DivProps>`
  vertical-align: middle;
  margin-left: 6px;
`
