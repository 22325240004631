import { Reducer } from 'redux'

import { RefinanciamentoTypes } from '@store/refinanciamento/actions'

import { ContatoState, ContatoTypes } from './types'

const INITIAL_STATE: ContatoState = {
  telefone: '',
  email: '',
  checkTelefone: false,
}

const reducer: Reducer<ContatoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContatoTypes.SET_TELEFONE:
      return { ...state, telefone: action.payload }
    case ContatoTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case ContatoTypes.SET_CHECK_TELEFONE:
      return { ...state, checkTelefone: action.payload }
    case RefinanciamentoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
