import { Reducer } from 'redux'

import { RefinanciamentoTypes } from '@store/refinanciamento/actions'

import { SimulacaoState, SimulacaoTypes } from './types'

const INITIAL_STATE: SimulacaoState = {
  parcelaAtual: {
    qtdeParcelas: 0,
    valorSolicitado: 0,
    valorSolicitadoPercentual: 0,
    valorParcela: 0,
    valorLiberado: 0,
    valorIOF: 0,
    percentualIOF: 0,
    valorFinanciado: 0,
    cETAm: 0,
    cETAe: 0,
    taxaNominalAm: 0,
    taxaNominalAa: 0,
    jurosContratados: 0,
    jurosContratadosPercentual: 0,
    dataUltimoVencimento: '',
    dataPrimeiroVencimento: '',
  },
  simulacoes: [],
  simulacoesComSeguro: [],
  saldoDevedor: 0,
  totalParcelas: 0,
  simulacaoSelecionada: 'simulacoesComSeguro',
  loadingSimulacao: false,
  errorSimulacao: false,
  loadingLead: false,
  errorLead: false,
  simulacaoId: 0,
  loadingGetSimulacao: false,
}

const reducer: Reducer<SimulacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoTypes.SIMULACAO_REQUEST:
      return { ...state, loadingSimulacao: true, errorSimulacao: false }
    case SimulacaoTypes.SIMULACAO_SUCCESS:
      return {
        ...state,
        loadingSimulacao: false,
        errorSimulacao: false,
        ...action.payload,
      }
    case SimulacaoTypes.SIMULACAO_ERROR:
      return { ...state, loadingSimulacao: false, errorSimulacao: true }

    case SimulacaoTypes.SALVAR_LEAD_REQUEST:
      return {
        ...state,
        loadingLead: true,
        errorLead: false,
        leadEnviado: false,
      }
    case SimulacaoTypes.SALVAR_LEAD_SUCCESS:
      return {
        ...state,
        loadingLead: false,
        errorLead: false,
        leadEnviado: true,
      }
    case SimulacaoTypes.SALVAR_LEAD_ERROR:
      return { ...state, loadingLead: false, errorLead: true }

    case SimulacaoTypes.SET_PARCELA_SELECIONADA:
      return { ...state, parcelaAtual: action.payload }
    case SimulacaoTypes.SET_SALDO_DEVEDOR:
      return { ...state, saldoDevedor: action.payload }
    case SimulacaoTypes.SET_TOTAL_PARCELAS:
      return { ...state, totalParcelas: action.payload }
    case SimulacaoTypes.SELECT_SIMULACAO:
      return { ...state, simulacaoSelecionada: action.payload }
    case SimulacaoTypes.SET_SIMULACAO_ID:
      return { ...state, simulacaoId: action.payload }
    case SimulacaoTypes.LOADING_SIMULACAO_ID:
      return { ...state, loadingGetSimulacao: action.payload }
    case RefinanciamentoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
