import React from 'react'

import { Paragraph } from '@atoms'
import { VerticalListNumber } from '@molecules'
import { Dialog } from '@organisms'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
}
export const BottomSheetContato = ({ setShowBottomSheet }: Props) => (
  <Dialog
    onClose={() => setShowBottomSheet(false)}
    headerTitle="Como alterar dados de contato da sua Conta Digital Inter"
    buttonText="Entendi"
  >
    <Paragraph margin="0 0 20px 0">Siga as etapas a seguir:</Paragraph>

    <VerticalListNumber
      list={[
        'Acesse a página inicial do Super App.',
        'Selecione os três pontinhos no canto superior direito da tela.',
        'Depois, selecione a opção ”Perfil".',
        'Em seguida, escolha o menu ”Atualização cadastral".',
        'Por fim, selecione ”Editar” (ícone) na parte de contatos.',
      ]}
    />
  </Dialog>
)
