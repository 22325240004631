import { BaseServices, HttpMethod } from '@services/base'

export interface DataType {
  cpf: string
  proposta: string
  clientId: string
}

export default ({ cpf, proposta, clientId }: DataType) => {
  const pathname = `${process.env.REACT_APP_GET_POST_CAPTURA_REFIN}?cpf=${cpf}&proposta=${proposta}&clientid=${clientId}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const response = BaseServices.request({
    pathname,
    method,
    host,
  })
  return response
}
