import { call, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { StepRefin } from '@utils/enums'
import postTrackingRefin, { TrackRefinRequest } from '@services/postTrackingRefin'

import { buildTrackingRequest } from './models'

export default function* postTrackRefin(action: AnyAction) {
  const payload = action.payload as StepRefin
  try {
    const state = yield* select((s: ApplicationState) => s)
    const tracking: TrackRefinRequest = buildTrackingRequest(state, payload)
    yield* call(postTrackingRefin, tracking)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('erro:', error)
  }
}
