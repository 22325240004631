import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const PdfGrid = styled.div<{
  isCartilha?: boolean
  isAdf?: boolean
}>`
  overflow: scroll;
  height: calc(100vh - 170px);
  margin: 0 -24px;
`

export const FooterGrid = styled.div`
  margin-bottom: 24px;
  padding: 0;
  background-color: ${Colors.WHITE};
`

export const PageVisualizarPdf = styled.div`
  margin: 24px;
  height: auto;
`

export const TextBoldOrange = styled.b`
  font-weight: 700;
  color: ${Colors.PRIMARY500};
`
