import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string | undefined) => {
  const pathname = `${process.env.REACT_APP_CONSULTAR_SIMULACAO}?cpf=${cpf}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<{ simulacaoId: number }>({
    pathname,
    method,
    host,
  })
  return response
}
