import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const Grid = styled.div`
  justify-content: left;
`
export const GridScroll = styled.div`
  max-height: calc(100vh - 280px);
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const LinkTermoAutorizacao = styled.a`
  color: ${Colors.PRIMARY500};
  font-weight: 700;
`
