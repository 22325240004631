import { combineReducers } from 'redux'

import navigation from './navigation'
import error from './error'
import { ErrorState } from './error/types'
import { NavigationState } from './navigation/types'

export interface UiState {
  navigation: NavigationState
  error: ErrorState
}

export default combineReducers({
  navigation,
  error,
})
