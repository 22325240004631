import React from 'react'

import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

import { Div, Span } from './styles'
import { Props, List, Border } from './types'

type OwnProps = Props & React.HtmlHTMLAttributes<HTMLDivElement>

const canUseBorder = (list: List[], index: number, border: Border) =>
  index ? !list[index - 1][border] : true

export const ListaDetalhes: React.FC<OwnProps> = ({
  title,
  list,
  borderTop,
  borderBottom,
  helper,
}) => (
  <>
    {title && (
      <Paragraph
        variant="sora"
        fontWeight="600"
        fontSize="16px"
        lineHeight="20px"
        color={Colors.GRAY500}
        margin="0"
        style={{
          borderTop: borderTop || '',
          borderBottom: borderBottom || '',
          minHeight: '40px',
        }}
      >
        {title}
      </Paragraph>
    )}
    {list.map((item, index) => (
      <Div
        key={item.title}
        data-testid="Div-lista-detalhes"
        borderTop={canUseBorder(list, index, 'borderBottom') ? item?.borderTop : ''}
        borderBottom={item?.borderBottom}
      >
        <>
          <Paragraph
            fontWeight={item?.fontWeight || '400'}
            fontSize="12px"
            lineHeight="14px"
            color={item?.color || Colors.GRAY400}
            margin="0"
          >
            {item.title}{' '}
            {item.iconBesideItemTitle ? item.iconBesideItemTitle : <Span>{helper}</Span>}
          </Paragraph>
          {item.values.map((value) => (
            <Paragraph
              fontWeight="600"
              fontSize="12px"
              lineHeight="14px"
              color={Colors.GRAY500}
              key={value}
              textAlign="right"
              margin="0"
            >
              {value}
            </Paragraph>
          ))}
        </>
      </Div>
    ))}
  </>
)
