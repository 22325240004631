import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string | undefined, beneficio: string | number | undefined) => {
  const pathname = `${process.env.REACT_APP_GET_DADOS_INSS}?cpf=${cpf}&beneficio=${beneficio}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
  })
  return response
}
