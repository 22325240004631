import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'

/**
 * Header com titulo do bottomsheet.
 */
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px 20px;
`

/**
 * Botão
 */
export const LargeButton = styled(Button)`
  width: 100%;
`
