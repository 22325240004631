import React from 'react'

import { Paragraph } from '@atoms'

import { Circle, LeftWidth, Row, Wrap } from './styles'

interface Props {
  list: string[]
}
export const VerticalListNumber = ({ list }: Props) => (
  <Wrap>
    {list.map((item, index) => (
      <Row key={item}>
        <LeftWidth>
          <Circle>{index + 1}</Circle>
        </LeftWidth>

        <Paragraph>{item}</Paragraph>
      </Row>
    ))}
  </Wrap>
)
