import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import { setLista } from '@store/refinanciamento/listaDocumentos/actions'
import { TipoDocs } from '@store/refinanciamento/listaDocumentos/types'
import postAssinarDocumentos from '@services/postAssinarDocumentos'

import { setAssinaturas } from '../actions'

interface Props {
  tipo: TipoDocs
  type: string
  email?: string
  telefone?: string
}

export default function* sendDocumentosAssinaturaSaga(action: AnyAction) {
  const payload = action.payload as Props
  const { tipo } = payload

  let listaDocumentos = yield* select(
    (state: ApplicationState) => state.refinanciamento.listaDocumentos,
  )
  const { origem } = yield* select((state: ApplicationState) => state.ui.navigation)
  const { numeroProposta } = yield* select(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )

  const { email, telefone } = yield* select(
    (state: ApplicationState) => state.refinanciamento.contato,
  )

  try {
    yield put(
      setLista({
        ...listaDocumentos,
        [tipo]: {
          ...listaDocumentos[tipo],
          isLoading: true,
          isError: false,
        },
      }),
    )

    const payloadAssinatura = {
      documento: tipo,
      origem,
      fluxo: 'ASSINATURA_DIGITAL_CAPTURA_COMPLETA',
      proposta: Number(numeroProposta).toString(),
      email,
      telefone,
    }

    const {
      data: { assinatura },
    } = yield* call(postAssinarDocumentos, payloadAssinatura)

    yield put(setAssinaturas(assinatura))

    listaDocumentos = yield* select(
      (state: ApplicationState) => state.refinanciamento.listaDocumentos,
    )
    yield put(
      setLista({
        ...listaDocumentos,
        [tipo]: {
          ...listaDocumentos[tipo],
          isLoading: false,
          isDone: true,
          isError: false,
        },
      }),
    )
  } catch (error) {
    listaDocumentos = yield* select(
      (state: ApplicationState) => state.refinanciamento.listaDocumentos,
    )
    yield put(
      setLista({
        ...listaDocumentos,
        [tipo]: {
          ...listaDocumentos[tipo],
          isLoading: false,
          isDone: false,
          isError: true,
        },
      }),
    )
    yield put(
      showError({
        title: 'Erro ao salvar o documento',
        message: 'Por favor tente novamente.',
        code: ErrorCodes.RE_004_ENVIAR_ASSINATURA,
      }),
    )
  }
}
