import styled from 'styled-components'

import { Colors } from '@utils/Colors'

import { JustifyContent, AlignItems, Direction } from './types'

/**
 * Div principal.
 */
export const BoxComponent = styled.div<{
  actived?: boolean
  margin?: string
  padding?: string
  minHeight?: string
  justify?: JustifyContent
  align?: AlignItems
  direction?: Direction
}>`
  ${(props) => props.margin && `margin: ${props.margin}`};

  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => props.direction || `row`};
  justify-content: ${(props) => props.justify || `center`};
  align-items: ${(props) => props.align || `center`};
  padding: ${(props) => props.padding || `16px 12px`};
  min-height: ${(props) => props.minHeight || `76px`};
  border-radius: 8px;
  border: 1px solid ${(props) => (props.actived ? Colors.PRIMARY500 : Colors.GRAY200)};
`
