import { BaseServices, HttpMethod } from '@services/base'

export default (data: SalvarLeadRequest) => {
  const pathname = process.env.REACT_APP_POST_SALVAR_REFINANCIAMENTO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SalvarLeadRequest {
  contratos: string[]
  cpf: string
  email: string
  telefoneCelular: string
  quantidadeParcelas: number
  valorParcela: number
  valorSolicitado: number
  saldoDevedor: number
  troco: number
  iof: number
  cet: number
  taxaJuros: number
  gaClid: string
  convenioId: number
  convenioDesc: string
  orgaoId: number
  orgaoDesc: string
  primeiroNome: string
  segundoNome: string
  origem: string
  utmSource: string
  utmContent: string
  utmMedium: string
  utmCampaign: string
  gaClientId: string
  clientId: string
  facebookId: string
  dataNascimento: string
  contatoWhatsapp: boolean
  dataPrimeiroDesconto: string
  dataUltimoDesconto: string
  creditoProtegido: boolean
  valorTotalFinal: number
  codProduto: number
}
