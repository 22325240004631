import styled from 'styled-components'

import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

export const BoxContato = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid ${Colors.GRAY200};
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 16px;
`
export const BoxTitle = styled.div`
  display: flex;
`
export const Title = styled(Paragraph)`
  align-self: center;
`

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`
export const Redirect = styled.a`
  font-family: inter;
  font-size: 12px;
  line-height: 15px;
  color: ${Colors.PRIMARY500};
  font-weight: 700;
`
