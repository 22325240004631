import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import {
  atualizarProposta,
  setPropostaAtualizada,
} from '@store/refinanciamento/documentosAssinatura/actions'
import { TipoEmprestimo } from '@utils/enums'

import { hideLoading, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
  }
  type: string
}

export default function* postAtualizarPropostaSaga({
  noRemoveLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => atualizarProposta(),
    actionTitle: 'Tentar Novamente',
  },
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    yield put(showLoading())
    if (!appState.refinanciamento.documentosAssinatura.propostaAtualizada) {
      const { status } = yield services.postAtualizarProposta({
        simulacaoId: String(appState.refinanciamento.simulacao.simulacaoId),
        documentoId: Number(appState.refinanciamento.proposta.documentoId),
        possuiSeguro:
          appState.refinanciamento.simulacao.simulacaoSelecionada ===
          TipoEmprestimo.CREDITO_PROTEGIDO,
        tipoSimulacao: 'REFINANCIAMENTO',
      })
      if (status === 200) {
        yield put(setPropostaAtualizada(true))
        return true
      }
    }
    if (!noRemoveLoader) {
      yield put(hideLoading())
    }
    if (appState.refinanciamento.documentosAssinatura.propostaAtualizada) return true
    yield put(showError(actionCallbackError))
    return false
  } catch (error) {
    yield put(showError(actionCallbackError))
    yield put(hideLoading())
    return false
  }
}
