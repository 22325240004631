import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { criarProposta, criarPropostaError, criarPropostaSuccess } from '../actions'

export default function* postCriarProposta() {
  const { simulacaoId } = yield* select(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  try {
    const { data } = yield* call(services.postProposta, {
      simulacaoId,
      tipoSimulacao: 'REFINANCIAMENTO',
    })
    yield put(criarPropostaSuccess(data.codigo))
  } catch (error) {
    yield put(criarPropostaError())
    yield put(
      showError({
        title: 'Erro ao criar proposta',
        message: 'Ocorreu um erro ao criar proposta',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.RE_007_CRIAR_PROPOSTA,
        actionCallback: () => criarProposta(),
      }),
    )
  }
}
