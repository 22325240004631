import { BaseServices, HttpMethod } from '@services/base'
import { PropostaApi } from '@store/refinanciamento/proposta/types'

export interface RequestDadosProposta {
  numeroProposta: string | number
  cpf: string
  email: string
  telefone: string
}

export default (data: RequestDadosProposta) => {
  const pathname = process.env.REACT_APP_DADOS_COMPLETOS_PROPOSTA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const response = BaseServices.request<PropostaApi>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
