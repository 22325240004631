import { put, select } from 'typed-redux-saga'

import { ApplicationState } from '@store/types'
import { postDadosPropostaSaga } from '@store/refinanciamento/proposta/sagas'

import postCaptureDocuments from './postCaptureDocuments'
import { buscarDocumentos, hideLoading, setEtapaBusca, showLoading } from '../actions'
import carregarCapturaSaga from './carregarCaptura'
import postDocumentosAssinaturaSaga from './postDocumentosAssinatura'
import postAtualizarPropostaSaga from './atualizarProposta'

const callbackError = () => ({
  message: 'Aguarde um instante e tente novamente.',
  actionCallback: () => buscarDocumentos(),
  actionTitle: 'Tentar Novamente',
})

export default function* buscarDocumentosSaga() {
  const { etapaBusca } = yield* select(
    (state: ApplicationState) => state.refinanciamento.documentos,
  )
  try {
    yield put(showLoading())
    let success = true
    if (!etapaBusca || etapaBusca <= 1) {
      yield put(setEtapaBusca(1))
      success = yield* postCaptureDocuments({
        noRemoveLoader: true,
        actionCallbackError: callbackError(),
        type: '',
      })
    }
    if (success && etapaBusca <= 2) {
      yield put(setEtapaBusca(2))
      success = yield* carregarCapturaSaga({
        notShowLoader: true,
        actionCallbackError: callbackError(),
        type: '',
      })
    }
    if (success && etapaBusca <= 3) {
      yield put(setEtapaBusca(3))
      success = yield* postAtualizarPropostaSaga({
        noRemoveLoader: true,
        actionCallbackError: callbackError(),
        type: '',
      })
    }
    if (success && etapaBusca <= 4) {
      yield put(setEtapaBusca(4))
      success = yield* postDocumentosAssinaturaSaga({
        noRemoveLoader: true,
        actionCallbackError: callbackError(),
        type: '',
      })
    }
    if (success && etapaBusca <= 5) {
      yield put(setEtapaBusca(5))
      success = yield* postDadosPropostaSaga({
        actionCallbackError: callbackError(),
        type: '',
      })
    }

    if (success) {
      yield put(hideLoading())
      return true
    }
    yield put(hideLoading())
    return false
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    yield put(hideLoading())
    return false
  }
}
