import { put, select } from 'typed-redux-saga'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import services from '@services'

import { infoValida, setContato, setValidaLoading } from '../actions'
import { CallbackInfoValida } from '../types'

export default function* postInfoValidaSaga(action: AnyAction) {
  const payload = action.payload as CallbackInfoValida
  try {
    yield put(setValidaLoading(true))
    const { documentoId } = yield* select((s: ApplicationState) => s.refinanciamento.proposta)
    const { data }: AxiosResponse = yield services.postInfoValida({
      documentoId,
    })
    yield put(setContato(data?.contato))
    yield put(setValidaLoading(false))
    payload.callback(data?.contato)
  } catch (error) {
    yield put(setValidaLoading(false))
    yield put(
      showError({
        title: 'Erro ao validar',
        message: 'Ocorreu um erro ao validar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        actionCallback: () => infoValida(payload),
      }),
    )
  }
}
