/**
 * Actions Types
 */

export enum DadosPessoaisTypes {
  SET_NOME = '@refinanciamento/dadosPessoais/SET_NOME',
  SET_CPF = '@refinanciamento/dadosPessoais/SET_CPF',
  SET_DATA_NASCIMENTO = '@refinanciamento/dadosPessoais/SET_DATA_NASCIMENTO',
  SET_CONTA = '@refinanciamento/dadosPessoais/SET_CONTA',
}

/**
 * State Type
 */

export interface DadosPessoaisState {
  readonly nome: string
  readonly cpf: string
  readonly dataNascimento: string
  readonly conta: string
}
