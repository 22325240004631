import { SalvarPropostaRequest } from '@services/postSalvarProposta'
import { ApplicationState } from '@store/types'

export const buildProposta = ({
  refinanciamento,
  session,
}: ApplicationState): SalvarPropostaRequest => ({
  clientId: String(session.sessionId || ''),
  cpf: String(refinanciamento.dadosPessoais.cpf || ''),
  escolaridade: refinanciamento.proposta.dadosPropostaAntiga.escolaridade,
  profissao: refinanciamento.proposta.dadosPropostaAntiga.profissao,
  banco: String(refinanciamento.consultaMargemInss.dadosBancarios.banco || ''),
  agencia: String(refinanciamento.consultaMargemInss.dadosBancarios.agencia || ''),
  conta: String(refinanciamento.consultaMargemInss.dadosBancarios.conta || ''),
  paisNaturalidade: String(refinanciamento.proposta.dadosPropostaAntiga.nacionalidade || ''),
  estadoNaturalidade: String(refinanciamento.proposta.dadosPropostaAntiga.estadoNaturalidade || ''),
  cidadeNaturalidade: String(refinanciamento.proposta.dadosPropostaAntiga.cidadeNaturalidade || ''),
  cep: refinanciamento.proposta.dadosPropostaAntiga.cepResidencial,
  endereco: String(refinanciamento.proposta.dadosPropostaAntiga.enderecoResidencial || ''),
  numero: String(refinanciamento.proposta.dadosPropostaAntiga.numeroResidencial || ''),
  complemento: String(refinanciamento.proposta.dadosPropostaAntiga.complementoResidencial || ''),
  bairro: String(refinanciamento.proposta.dadosPropostaAntiga.bairroResidencial || ''),
  estado: String(refinanciamento.proposta.dadosPropostaAntiga.estadoResidencial || ''),
  cidade: String(refinanciamento.proposta.dadosPropostaAntiga.cidadeResidencial || ''),
  termoAceite: true,
  numeroProposta: String(refinanciamento.proposta.numeroProposta || ''),
  tipoLiberacao: verificarTipoLiberacao(
    refinanciamento.consultaMargemInss.dadosBancarios.tipoCredito,
  ),
  ipCliente: '0.0.0.0',
  digitoConta: Number(refinanciamento.consultaMargemInss.dadosBancarios.digitoConta),
  simulacaoId: refinanciamento.simulacao.simulacaoId,
})

export const verificarTipoLiberacao = (tipoLiberacao: string) => {
  if (tipoLiberacao === 'TED - Conta Corrente' || tipoLiberacao === 'Conta Corrente') return 1
  return 2
}

export const buildDocumentoId = ({ refinanciamento }: ApplicationState) => ({
  documentoId: String(refinanciamento.proposta.documentoId || ''),
})
