import { InfoParcelas } from '@store/refinanciamento/simulacao/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (data: SimulacaoRequest) => {
  const pathname = process.env.REACT_APP_POST_SIMULACAO_REFINANCIAMENTO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<SimulacaoResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SimulacaoRequest {
  cpf: string
  saldoDevedor: number
  valorParcela: number
  numeroPrimeiraProposta: string
  taxaJuros: number
}

export interface SimulacaoResponse {
  simulacoes: InfoParcelas[]
  simulacoesComSeguro: InfoParcelas[]
}
