import styled from 'styled-components'

import { Colors } from '@utils/Colors'
import { Paragraph } from '@atoms'

export const LinkPrivacidade = styled.a`
  color: ${Colors.PRIMARY500};
  background-color: transparent;
  text-decoration: none;
  font-weight: 700;
`
export const ImgOnboarding = styled.img`
  @media (max-width: 500px) {
    width: 100vw;
  }
  width: 100%;
  margin: 0 -24px;
`
export const PFlexStart = styled(Paragraph)`
  align-self: flex-start;
`

export const WrapLoadingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
`
