import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'
import { Separator } from '@atoms'

export const WrapIcon = styled.div`
  margin-left: 10px;
`

export const WrapButton = styled(Button)`
  margin-top: 14px;
`
export const CircleIcon = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Colors.GRAY100};
`

export const AreaSeguro = styled.div`
  display: flex;
  background-color: ${Colors.GRAY100};
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
`

export const Line = styled.div`
  display: flex;
  flex-direction: row;
`

export const Bold = styled.b`
  font-weight: 700;
  color: ${Colors.GRAY500};
`
export const StyledSeparator = styled(Separator)`
  width: 100%;
  height: 0.5px;
  margin: 16px 0;
`
