import { action, Action } from 'typesafe-actions'

import { DadosPessoaisTypes } from './types'

export const setNome = (payload: string): Action => action(DadosPessoaisTypes.SET_NOME, payload)

export const setCpf = (payload: string): Action => action(DadosPessoaisTypes.SET_CPF, payload)

export const setDataNascimento = (payload: string): Action =>
  action(DadosPessoaisTypes.SET_DATA_NASCIMENTO, payload)

export const setConta = (payload: string): Action => action(DadosPessoaisTypes.SET_CONTA, payload)
