import { action, Action } from 'typesafe-actions'

import { ValidaTypes, ValidaRequestPayload, PayloadAutorizacao } from './types'

export const validaRequest = (payload: ValidaRequestPayload): Action =>
  action(ValidaTypes.VALIDA_REQUEST, payload)

export const setLoading = (payload: boolean): Action =>
  action(ValidaTypes.VALIDA_SET_LOADING, payload)

export const postIsafeAutorizacao = (payload: PayloadAutorizacao): Action =>
  action(ValidaTypes.POST_ISAFE_AUTORIZACAO, payload)
