import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const LinkTermoAutorizacao = styled.a`
  color: ${Colors.PRIMARY500};
  font-weight: 700;
`
export const WrapLoadingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
`
