import { BaseServices, HttpMethod } from '@services/base'

export interface DataAttPropostaType {
  simulacaoId: string
  documentoId: number
  possuiSeguro?: boolean
  tipoSimulacao?: string
}

export default async (data: DataAttPropostaType) => {
  const pathname = process.env.REACT_APP_PROPOSTA_ATUALIZAR
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
