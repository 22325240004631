import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { getContratos } from '@store/refinanciamento/contratos/sagas'

import { SimulacaoTypes } from './simulacao/types'
import { ContratosTypes } from './contratos/types'
import {
  postSimulacao,
  postSalvarLead,
  postTrackingRefin,
  getSimulacaoIdSaga,
} from './simulacao/sagas'
import { ConsultaMargemInssTypes } from './consultaMargemInss/types'
import { postConsultaMargemInssSaga } from './consultaMargemInss/sagas'
import { ValidaTypes } from './valida/types'
import { postAutorizacaoISafeSaga, postValida } from './valida/sagas'
import { DocumentosAssinaturaTypes } from './documentosAssinatura/types'
import {
  editarLeadSaga,
  finalizarPropostaSaga,
  sendDocumentosAssinatura,
} from './documentosAssinatura/sagas'
import { postDocumentoAssincronoSaga } from './documentosAssinatura/sagas'
import { PropostaTypes } from './proposta/types'
import {
  postCriarProposta,
  postDadosPropostaSaga,
  postInfoValidaSaga,
  postSalvarPropostaSaga,
} from './proposta/sagas'
import { DocumentosTypes } from './documentos/types'
import {
  buscarDocumentosSaga,
  carregarCapturaSaga,
  postCapturaDocumentosSaga,
  postDocumentosAssinaturaSaga,
} from './documentos/sagas'
import postDadosAntigaPropostaSaga from './proposta/sagas/postDadosAntigaPropostaSaga'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(ContratosTypes.CONTRATOS_REQUEST, getContratos)
  yield* takeLatest(SimulacaoTypes.SIMULACAO_REQUEST, postSimulacao)
  yield* takeLatest(SimulacaoTypes.SALVAR_LEAD_REQUEST, postSalvarLead)
  yield* takeLatest(SimulacaoTypes.TRACKING_REFIN, postTrackingRefin)
  yield* takeLatest(
    ConsultaMargemInssTypes.CONSULTA_MARGEM_INSS_REQUEST,
    postConsultaMargemInssSaga,
  )
  yield* takeLatest(ValidaTypes.VALIDA_REQUEST, postValida)
  yield* takeLatest(DocumentosAssinaturaTypes.SEND_ASSINATURA, sendDocumentosAssinatura)
  yield* takeLatest(
    DocumentosAssinaturaTypes.SEND_DOCUMENTO_ASSINCRONO,
    postDocumentoAssincronoSaga,
  )
  yield* takeLatest(PropostaTypes.DADOS_PROPOSTA_REQUEST, postDadosPropostaSaga)
  yield* takeLatest(SimulacaoTypes.GET_SIMULACAO_ID, getSimulacaoIdSaga)
  yield* takeLatest(ValidaTypes.POST_ISAFE_AUTORIZACAO, postAutorizacaoISafeSaga)
  yield* takeLatest(PropostaTypes.CRIAR_PROPOSTA, postCriarProposta)

  yield* takeLatest(DocumentosTypes.CAPTURA_DOCUMENTOS_REQUEST, postCapturaDocumentosSaga)
  yield* takeLatest(DocumentosTypes.CARREGAR_CAPTURA_REQUEST, carregarCapturaSaga)
  yield* takeLatest(DocumentosTypes.DOCUMENTOS_ASSINATURA_REQUEST, postDocumentosAssinaturaSaga)
  yield* takeLatest(DocumentosTypes.BUSCAR_DOCUMENTOS_SAGA, buscarDocumentosSaga)
  yield* takeLatest(DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA, finalizarPropostaSaga)
  yield* takeLatest(PropostaTypes.SALVAR_PROPOSTA_REQUEST, postSalvarPropostaSaga)
  yield* takeLatest(DocumentosAssinaturaTypes.EDITAR_LEAD, editarLeadSaga)
  yield* takeLatest(PropostaTypes.DADOS_PROPOSTA_ANTIGA_REQUEST, postDadosAntigaPropostaSaga)
  yield* takeLatest(PropostaTypes.INFO_VALIDA, postInfoValidaSaga)
}
